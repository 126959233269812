import React from "react";

const Spacebothelp = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                <path d="M8.80645 17.8224H7.3871V16.4031H10.2258C10.6022 16.4031 10.9633 16.2535 11.2294 15.9874C11.4956 15.7212 11.6452 15.3602 11.6452 14.9837V13.4465L9.67581 13.901C9.55029 13.93 9.41926 13.9243 9.29676 13.8844C9.17425 13.8446 9.0649 13.7722 8.98043 13.6749C8.89595 13.5777 8.83954 13.4593 8.81723 13.3324C8.79493 13.2055 8.80758 13.075 8.85382 12.9548L9.68769 10.7873C8.98828 9.59051 8.69305 8.20021 8.84575 6.82244H5.4955L4.89178 3.50195L3.49531 3.75584L4.0529 6.82244H3.12903C2.7526 6.82244 2.39158 6.97198 2.1254 7.23816C1.85922 7.50434 1.70968 7.86536 1.70968 8.2418V14.9837C1.70968 15.3602 1.85922 15.7212 2.1254 15.9874C2.39158 16.2535 2.7526 16.4031 3.12903 16.4031H5.96774V17.8224H4.54839C3.60762 17.8235 2.7057 18.1977 2.04048 18.8629C1.37526 19.5281 1.00107 20.4301 1 21.3708V22.7902C1 22.9784 1.07477 23.1589 1.20786 23.292C1.34095 23.4251 1.52146 23.4999 1.70968 23.4999H4.54839V22.0805H8.80645V23.4999H11.6452C11.8334 23.4999 12.0139 23.4251 12.147 23.292C12.2801 23.1589 12.3548 22.9784 12.3548 22.7902V21.3708C12.3538 20.4301 11.9796 19.5281 11.3144 18.8629C10.6491 18.1977 9.74722 17.8235 8.80645 17.8224ZM7.3871 10.3708H8.80645V11.7902H7.3871V10.3708ZM4.54839 10.3708H5.96774V11.7902H4.54839V10.3708ZM4.54839 13.5644H8.80645V14.9837H4.54839V13.5644ZM8.80645 20.6612H4.54839V19.2418H8.80645V20.6612Z" fill="#E1B028" />
                <path d="M15.9032 1.5C12.3814 1.5 9.51611 4.20609 9.51611 7.53226C9.51777 8.55079 9.78861 9.55079 10.3012 10.4309L10.472 10.7249L9.51611 13.2097L12.3539 12.5548L12.5953 12.6932C13.6032 13.2668 14.7435 13.5671 15.9032 13.5645C19.425 13.5645 22.2903 10.8584 22.2903 7.53226C22.2903 4.20609 19.425 1.5 15.9032 1.5ZM12.4002 5.1536C12.4509 5.01844 12.5417 4.90198 12.6605 4.81978C12.7792 4.73758 12.9201 4.69354 13.0645 4.69355H18.7419C18.8863 4.69354 19.0273 4.73758 19.146 4.81978C19.2647 4.90198 19.3555 5.01844 19.4063 5.1536C19.5102 5.43037 16.3705 8.42117 16.3705 8.42117C16.2412 8.53437 16.0751 8.59676 15.9032 8.59676C15.7313 8.59676 15.5653 8.53437 15.4359 8.42117C15.4359 8.42117 12.2962 5.43037 12.4002 5.1536ZM19.4516 9.66129C19.4516 9.84951 19.3768 10.03 19.2437 10.1631C19.1106 10.2962 18.9301 10.371 18.7419 10.371H13.0645C12.8763 10.371 12.6958 10.2962 12.5627 10.1631C12.4296 10.03 12.3548 9.84951 12.3548 9.66129V6.31396C12.6071 6.59784 12.9487 6.95875 13.3739 7.38961C14.1446 8.17075 14.9385 8.92748 14.9464 8.93502L14.9572 8.94531L14.9685 8.95521C15.2272 9.18164 15.5594 9.30646 15.9032 9.30646C16.247 9.30646 16.5792 9.18164 16.8379 8.95521L16.8492 8.94531L16.86 8.93502C16.8679 8.92748 17.6618 8.17075 18.4325 7.38961C18.8577 6.95875 19.1993 6.59806 19.4516 6.31396V9.66129Z" fill="#E1B028" />
            </svg>
        </>
    );
};

export default Spacebothelp;