import React, { useState } from "react";
import Header from "../directives/Header";
import Footer from "../directives/Footer";
import "../componentCss/home.css";
import "../componentCss/about.css"
import "../componentCss/feature.css"
import {
    Container,
    Row,
    Col,
    Button,
    Card
} from "react-bootstrap";
import { Zoom, Fade, Roll, Slide } from 'react-reveal';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaPlay } from "react-icons/fa";



const Feature = () => {
    const [isCollapsed, setIsCollapsed] = useState(true);

    const handleToggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    centerPadding: "60px",
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <>
            <Header />
            <div className="subheader" style={{ background: "url(assets/images/service/banner/young-business.png)", backgroundRepeat: "no-repeat", backgroundSize: "cover" }} >
                <div className="mt-5 d-flex justify-content-center align-items-center" style={{ height: "500px" }} >
                    <h2 className=" text-uppercase fw-medium">
                        <span className="text-primary">Feature</span>
                    </h2>
                </div>

            </div>
            <div className="feature">
                <Zoom>
                    <div className="feature-list" style={{ marginTop: " -85px" }}>
                        <Container>
                            <Row>
                                <Col xl={2} lg={4} md={4} sm={6} xs={6} className="mb-3 text-center">

                                    <div className="box">
                                        <img src="assets/images/icon/forex.svg" />
                                        <div className="small">NO FOREX TRADING</div>
                                    </div>


                                </Col>
                                <Col xl={2} lg={4} md={4} sm={6} xs={6} className="mb-3 text-center">

                                    <div className="box">
                                        <img src="assets/images/icon/business-modal.svg" />
                                        <div className="small">NO BUSINESS MODEL</div>
                                    </div>


                                </Col>
                                <Col xl={2} lg={4} md={4} sm={6} xs={6} className="mb-3 text-center">

                                    <div className="box">
                                        <img src="assets/images/icon/sell-and-buy.svg" />
                                        <div className="small">NO BUYING <br /> NO SELLING</div>
                                    </div>


                                </Col>
                                <Col xl={2} lg={4} md={4} sm={6} xs={6} className="mb-3 text-center">

                                    <div className="box">
                                        <img src="assets/images/icon/return-on-investment.svg" />
                                        <div className="small">NO INVESTMENT ACTIVITY</div>
                                    </div>


                                </Col>
                                <Col xl={2} lg={4} md={4} sm={6} xs={6} className="mb-3 text-center">

                                    <div className="box">
                                        <img src="assets/images/icon/subscription.svg" />
                                        <div className="small">NO MONTHLY SUBSCRIPTION</div>
                                    </div>


                                </Col>
                                <Col xl={2} lg={4} md={4} sm={6} xs={6} className="mb-3 text-center">

                                    <div className="box">
                                        <img src="assets/images/icon/organization.svg" />
                                        <div className="small">NO MIDDLEMAN <br /> NO COMPANY</div>
                                    </div>


                                </Col>
                            </Row>
                        </Container>

                    </div>
                </Zoom>
            </div>


            <div className="bg-galaxy featureBox py-lg-5">

                <section className="py-lg-5 py-md-5 py-sm-5  pt-3 position-relative">

                    <Container>
                        <Row className="justify-content-center">
                            <Col lg={10} md={10} className="mb-1">
                                <Fade top>
                                    <div className="heading text-center">
                                        <h2 className="fw-bold lh-base mb-0">Global Automated Powerline Placement in Hi5 Space</h2>
                                    </div>
                                </Fade>
                            </Col>
                            <Col lg={12} className="mb-4 text-center">
                                <small>is a unique system designed to facilitate the matrix structure from Space 2 to Space 5.
                                    This innovative approach ensures a dynamic and globally interconnected placement
                                    system, enhancing the overall experience for participants within the Hi5 Space community.</small>
                            </Col>
                        </Row>
                    </Container>
                    <Container className=" my-3">
                        <Row>
                            <Col lg={4} className="mb-4">
                                <Card className="bg-light-grey p-4 rounded-5 border border-dark">
                                    <div className="mb-3">
                                        <img src="assets/images/service/icon/User.png" width={`70px`} />
                                    </div>
                                    <div>
                                        <h5 className="mb-3">Unified Global Community Network</h5>
                                        <ul>
                                            <li className="mb-2">The matrix structure placement
                                                from space 2 to space 5 is not
                                                limited by upline or downline.</li>
                                            <li>Determined by a unified global community network.</li>
                                        </ul>
                                    </div>

                                </Card>
                            </Col>
                            <Col lg={4} className="mb-4">
                                <Card className="bg-light-grey p-4 rounded-5 border border-dark">
                                    <div className="mb-3">
                                        <img src="assets/images/service/icon/space.png" width={`70px`} />
                                    </div>
                                    <div>
                                        <h5 className="mb-3">Space Entry Position Placement</h5>
                                        <ul>
                                            <li className="mb-2">Every space entry position is placed under a previously joined matrix position.</li>
                                            <li>Placement is determined according to the polygon transaction timestamp by the Hi5 Space Loop.</li>
                                        </ul>
                                    </div>

                                </Card>
                            </Col>
                            <Col lg={4} className="mb-4">
                                <Card className="bg-light-grey p-4 rounded-5 border border-dark">
                                    <div className="mb-3">
                                        <img src="assets/images/service/icon/Distributed.png" width={`70px`} />
                                    </div>
                                    <div>
                                        <h5 className="mb-3">Seamless ReEntry Integration</h5>
                                        <ul>
                                            <li className="mb-2">Upon reentry, all previous positions seamlessly reintegrate into the space matrix.</li>
                                            <li>Uplines and downlines are not constrained by specific arrangements.</li>
                                        </ul>
                                    </div>

                                </Card>
                            </Col>
                            <Col lg={4} className="mb-4">
                                <Card className="bg-light-grey p-4 rounded-5 border border-dark">
                                    <div className="mb-3">
                                        <img src="assets/images/service/icon/setting.png" width={`70px`} />
                                    </div>
                                    <div>
                                        <h5 className="mb-3">Auto Social Responsibility</h5>
                                        <ul>
                                            <li className="mb-2">Early joiners with successful matrix&nbsp;
                                                donation receivers take on auto social
                                                responsibility.</li>
                                            <li className="">They assist in filling up space matrix
                                                positions for newcomers, irrespective
                                                of their invite network.</li>
                                        </ul>
                                    </div>

                                </Card>
                            </Col>
                            <Col lg={4} className="mb-4">
                                <Card className="bg-light-grey p-4 rounded-5 border border-dark">
                                    <div className="mb-3">
                                        <img src="assets/images/service/icon/envelop.png" width={`70px`} />
                                    </div>
                                    <div>
                                        <h5 className="mb-3">Opportunity without Inviting</h5>
                                        <ul>
                                            <li className="mb-2">Participants have the opportunity to receive multiple donations from the global community space matrix.</li>
                                            <li>This opportunity is available even without inviting anyone, across all space positions.</li>
                                        </ul>
                                    </div>

                                </Card>
                            </Col>
                            <Col lg={4} className="mb-4">
                                <Card className="bg-light-grey p-4 rounded-5 border border-dark">
                                    <div className="mb-3">
                                        <img src="assets/images/service/icon/blocks.png" width={`70px`} />
                                    </div>
                                    <div>
                                        <h5 className="mb-3">Global OneLine Structure System</h5>
                                        <ul>
                                            <li className="mb-2">Operates based on global powerline
                                                movement.</li>
                                            <li className="">Generative space
                                                position spillover feature
                                                ensures continuous
                                                growth within the space
                                                matrix positions.</li>
                                        </ul>
                                    </div>

                                </Card>
                            </Col>
                            <Col lg={4} className="mb-4">
                                <Card className="bg-light-grey p-4 rounded-5 border border-dark">
                                    <div className="mb-3">
                                        <img src="assets/images/service/icon/blockchain.png" width={`70px`} />
                                    </div>
                                    <div>
                                        <h5 className="mb-3">Timestamp Record by Polygon Blockchain</h5>
                                        <ul>
                                            <li className="mb-2">Timestamp is a time recorded by the polygon blockchain.</li>
                                            <li className="">It provides the received time of the respective transaction of space donation payment.</li>
                                        </ul>
                                    </div>

                                </Card>
                            </Col>

                            <Col lg={4} className="mb-4">
                                <Card className="bg-light-grey p-4 rounded-5 border border-dark">
                                    <div className="mb-3">
                                        <img src="assets/images/service/icon/polygon blockchain.png" width={`70px`} />
                                    </div>
                                    <div>
                                        <h5 className="mb-3">Polygon Blockchain</h5>
                                        <ul>
                                            <li className="mb-2">Lifetime Permanent Blockchain Position</li>
                                            <li className="mb-2">Receive Your Donations
                                                Directly Into Your Private
                                                Polygon Wallet</li>
                                            {/* <li className="">Directly Into Your Private Polygon Wallet</li> */}
                                        </ul>
                                    </div>

                                </Card>
                            </Col>
                            <Col lg={4} className="mb-4">
                                <Card className="bg-light-grey p-4 rounded-5 border border-dark">
                                    <div className="mb-3">
                                        <img src="assets/images/service/icon/Easy Wallet.png" width={`70px`} />
                                    </div>
                                    <div>
                                        <h5 className="mb-3">Easy Wallet Connection</h5>
                                        <ul>
                                            <li className="mb-2">We are Supported By More than 100 wallets</li>
                                            <li className="mb-2">Working for everyone by P2P wallet interaction</li>

                                        </ul>
                                    </div>

                                </Card>
                            </Col>

                        </Row>
                    </Container>
                </section>
                <section className="py-lg-5 py-md-5 py-sm-5  pt-3 position-relative">

                    <Container>
                        <Row className="justify-content-center">
                            <Col lg={10} md={10} className="mb-1">
                                <Fade top>
                                    <div className="heading text-center">
                                        <h2 className="fw-bold lh-base mb-0">Hi5 Space Loop: Revolutionizing Wealth Distribution</h2>
                                    </div>
                                </Fade>
                            </Col>
                            <Col lg={12} className="mb-4 text-center">
                                <small>This Hi5 Space Loop engineering generates an innovative balance of wealth distribution position in our ecosystem. Now, everyone has a space to be a
                                    winner in our Hi5 Space community. The Space Loop unites them together globally with the destiny to help enrich every position with unlimited donations.</small>
                            </Col>
                        </Row>
                    </Container>
                    <Container className=" my-3">
                        <Row>
                            <Col lg={4} className="mb-4">
                                <Card className="bg-light-grey p-4 rounded-5 border border-dark">
                                    <div className="mb-3">
                                        <img src="assets/images/service/icon/dollar.png" width={`70px`} />
                                    </div>
                                    <div>
                                        <h5 className="mb-3">Harmonious Wealth Distribution</h5>
                                        <ul>
                                            <li className="mb-2">Space Loop ensures a harmonious distribution of positions within the Hi5 Space ecosystem.</li>
                                            <li className="">Balances the wealth distribution to create a fair and inclusive environment.</li>
                                        </ul>
                                    </div>

                                </Card>
                            </Col>
                            <Col lg={4} className="mb-4">
                                <Card className="bg-light-grey p-4 rounded-5 border border-dark">
                                    <div className="mb-3">
                                        <img src="assets/images/service/icon/doublesetting.png" width={`70px`} />
                                    </div>
                                    <div>
                                        <h5 className="mb-3">Recurrent Automatic Dynamic Rebirth</h5>
                                        <ul>
                                            <li className="mb-2">Participants benefit from recurrent automatic dynamic rebirth space matrix positions.</li>
                                            <li className="">This feature enhances the chances of receiving donations repeatedly.</li>
                                        </ul>
                                    </div>

                                </Card>
                            </Col>
                            <Col lg={4} className="mb-4">
                                <Card className="bg-light-grey p-4 rounded-5 border border-dark">
                                    <div className="mb-3">
                                        <img src="assets/images/service/icon/circlearrow.png" width={`70px`} />
                                    </div>
                                    <div>
                                        <h5 className="mb-3">Automatic ReEntry Positions</h5>
                                        <ul>
                                            <li className="mb-2">The system seamlessly facilitates automatic reentry positions.</li>
                                            <li className="">Participants can reenter the space matrix efficiently, contributing to sustained engagement.
                                            </li>
                                        </ul>
                                    </div>

                                </Card>
                            </Col>
                            <Col lg={4} className="mb-4">
                                <Card className="bg-light-grey p-4 rounded-5 border border-dark">
                                    <div className="mb-3">
                                        <img src="assets/images/service/icon/setting.png" width={`70px`} />
                                    </div>
                                    <div>
                                        <h5 className="mb-3">Automatic Space Upgrade Positions</h5>
                                        <ul>
                                            <li className="mb-2">Space Loop introduces automatic space upgrade positions.</li>
                                            <li className="">Upgrading positions occurs seamlessly, enhancing the overall experience for participants.</li>
                                        </ul>
                                    </div>

                                </Card>
                            </Col>

                            <Col lg={4} className="mb-4">
                                <Card className="bg-light-grey p-4 rounded-5 border border-dark">
                                    <div className="mb-3">
                                        <img src="assets/images/service/icon/pay it forward.png" width={`70px`} />
                                    </div>
                                    <div>
                                        <h5 className="mb-3">Pay It Forward Positions</h5>
                                        <ul>
                                            <li className="mb-2">Helping people to join free and triggering a chain reaction within our space community.</li>
                                            <li className="">Promote a global social
                                                entrepreneurship
                                                movement in the
                                                community.</li>
                                        </ul>
                                    </div>

                                </Card>
                            </Col>
                            <Col lg={4} className="mb-4">
                                <Card className="bg-light-grey p-4 rounded-5 border border-dark">
                                    <div className="mb-3">
                                        <img src="assets/images/service/icon/innovative space.png" width={`70px`} />
                                    </div>
                                    <div>
                                        <h5 className="mb-3">Innovative Space Loop Ecosystem</h5>
                                        <ul>
                                            <li className="mb-2">The Space Loop connects
                                                them globally with the
                                                mission to enrich everyone.</li>
                                            <li className="">Ensuring that every member of our Hi5 Space
                                                community has the opportunity to thrive and
                                                succeed.</li>
                                        </ul>
                                    </div>

                                </Card>
                            </Col>


                        </Row>
                    </Container>
                </section>
                <section className="py-lg-5 py-md-5 py-sm-5  pt-3 position-relative">

                    <Container>
                        <Row className="justify-content-center">
                            <Col lg={10} md={10} className="mb-1">
                                <Fade top>
                                    <div className="heading text-center">
                                        <h2 className="fw-bold lh-base mb-0">Reciprocal Community Donation in Hi5 Space</h2>
                                    </div>
                                </Fade>
                            </Col>
                            <Col lg={12} className="mb-4 text-center">
                                <small>Reciprocal donation concept promotes a sense of shared social responsibility within the community with a holistic approach
                                    towards building a better world for everyone</small>
                            </Col>
                        </Row>
                    </Container>
                    <Container className=" my-3">
                        <Row>
                            <Col lg={4} className="mb-4">
                                <Card className="bg-light-grey p-4 rounded-5 border border-dark">
                                    <div className="mb-3">
                                        <img src="assets/images/service/icon/space.png" width={`70px`} />
                                    </div>
                                    <div>
                                        <h5 className="mb-3">Allocation of Positions</h5>
                                        <ul>
                                            <li className="mb-2">Out of the total 155
                                                positions in the space
                                                matrix, 50 positions are
                                                dedicated to Reciprocal
                                                Community Donation.</li>

                                            {/* <li className="">50 positions are dedicated to Reciprocal Community Donation.</li> */}
                                        </ul>
                                    </div>

                                </Card>
                            </Col>
                            <Col lg={4} className="mb-4">
                                <Card className="bg-light-grey p-4 rounded-5 border border-dark">
                                    <div className="mb-3">
                                        <img src="assets/images/service/icon/doublesetting.png" width={`70px`} />
                                    </div>
                                    <div>
                                        <h5 className="mb-3">Contribution Mechanism</h5>
                                        <ul>
                                            <li className="">All space matrix positions
                                                contribute to the Reciprocal
                                                Community Donation through activities such as
                                                reentry, upgrades, and the pay
                                                it forward system.</li>
                                        </ul>
                                    </div>

                                </Card>
                            </Col>
                            <Col lg={4} className="mb-4">
                                <Card className="bg-light-grey p-4 rounded-5 border border-dark">
                                    <div className="mb-3">
                                        <img src="assets/images/service/icon/setting.png" width={`70px`} />
                                    </div>
                                    <div>
                                        <h5 className="mb-3">Utilization in Auto Space Builder</h5>
                                        <ul>
                                            <li className="">The Reciprocal Community
                                                Donation system elevates
                                                participants and facilitates auto
                                                re-entry with reserve donations.
                                                Meanwhile, the Pay It Forward
                                                system empowers contributors
                                                to support newcomers.</li>
                                        </ul>
                                    </div>

                                </Card>
                            </Col>

                            <Col lg={12} className="mt-3">
                                <div>

                                    {!isCollapsed && <div>
                                        <p className="">This concept of reciprocal donations emphasizes the importance of generosity and gratitude in bringing people together and creating a positive impact on the Hi5 Space
                                            community.</p>
                                        <p className="">When we give willingly, we are demonstrating kindness and compassion towards others, which can inspire them to do the same. This act of giving can also create a sense of unity
                                            among the community, as it fosters a spirit of cooperation and mutual support. Similarly, when we accept gratefully, we acknowledge the generosity of others and express our
                                            appreciation for their actions. This act of gratitude can strengthen relationships, build trust, and create a positive cycle of giving and receiving.</p>
                                        <p className="">Together, these acts of giving and receiving can lead to a collective sense of well-being and blessings for all. By fostering collaboration and cooperation in this way, we can create a
                                            more harmonious and connected social entrepreneur community, where everyone can thrive. When social entrepreneurs come together, they can leverage their diverse skills,
                                            experiences, and resources to address complex social challenges more effectively.</p>

                                    </div>}
                                    <div className="text-center">
                                        <Button variant="primary" className="my-3 px-4" onClick={handleToggleCollapse}>
                                            {isCollapsed ? 'Show More' : 'Show Less'}
                                        </Button>
                                    </div>
                                </div>
                            </Col>


                        </Row>
                        <br />
                        <Row>
                            <Col lg={12} className="mb-4">
                                <Fade top>
                                    <div className="heading">
                                        <h3 className="fw-bold lh-base mb-0">JOIN Hi5 SPACE WITH TEAMS OF PEOPLE DRIVEN BY LOVE, COMPASSION, AND GENEROSITY TO STRENGTHEN SOCIAL
                                            RESPONSIBILITY AND UPLIFT COMMUNITIES.</h3>
                                    </div>
                                </Fade>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </div>
            <div className="globe_earth">
                <Footer />
            </div>
        </>
    );
};

export default Feature;
