import React, { useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Container,
  Row,
  Col,
  Card,
  Table,
  Form,
  InputGroup,
  Button,
  Modal,
} from "react-bootstrap";
import Sidebar from "../Sidebar/index.jsx";
import Box from "@mui/material/Box";
import "../css/spacewall.css";
import "../css/spaceverification.css";
import Select from "react-select";
import countryList from "react-select-country-list";
import { flavourOptions } from "../../../data.ts";
import chroma from "chroma-js";
import config from "../../../coreFiles/config.jsx";
import copy from "copy-to-clipboard";
import toast, { Toaster } from "react-hot-toast";

// const colourStyles: StylesConfig<ColourOption> = {
//     control: (styles) => ({ ...styles, backgroundColor: 'white' }),
//     option: (styles, { data, isDisabled, isFocused, isSelected }) => {
//         const color = chroma(data.color);
//         return {
//             ...styles,
//             backgroundColor: isDisabled
//                 ? undefined
//                 : isSelected
//                     ? data.color
//                     : isFocused
//                         ? color.alpha(0.1).css()
//                         : undefined,
//             color: isDisabled
//                 ? '#ccc'
//                 : isSelected
//                     ? chroma.contrast(color, 'white') > 2
//                         ? 'white'
//                         : 'black'
//                     : data.color,
//             cursor: isDisabled ? 'not-allowed' : 'default',

//             ':active': {
//                 ...styles[':active'],
//                 backgroundColor: !isDisabled
//                     ? isSelected
//                         ? data.color
//                         : color.alpha(0.3).css()
//                     : undefined,
//             },
//         };
//     }
// };

const Sociallink = () => {
  const loginDetails = useSelector((state) => state.auth.LoginDetails[0]);

  const [value, setValue] = useState(
    `${config.linkUrl}${loginDetails.address}`
  );

  const [socialLinksAndNames, setSocialLinksAndNames] = useState([
    {
      name: "whatsapp",
      link: `https://api.whatsapp.com/send?text=${encodeURIComponent(value)}`,
    },
    {
      name: "instagram",
      link: `https://www.instagram.com/share?url=${encodeURIComponent(value)}`,
    },
    {
      name: "telegram",
      link: `https://t.me/share/url?url=${encodeURIComponent(value)}`,
    },
    {
      name: "gmail",
      link: `mailto:?subject=Check%20this%20out&body=${encodeURIComponent(
        value
      )}`,
    },
  ]);
  const options = useMemo(() => countryList().getData(), []);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show, setShow] = useState(false);

  const changeHandler = (value) => {
    setValue(value);
  };
  const copyText = (e) => {
    e.preventDefault();
    copy(value);
    toast.success("copied");
  };

  return (
    <>
      <Box sx={{ display: "-webkit-box" }} className="dashboard">
        <Toaster />

        <Sidebar />
        <Box
          component="main"
          sx={{ flexGrow: 1, p: 3 }}
          style={{ marginTop: "74px" }}
          className="text-white mainBox"
        >
          <Row>
            <Col lg={12} className="mb-4 mt-5 pt-5">
              <Row className="justify-content-center">
                <Col lg={7}>
                  <div className="heading mb-3">
                    <h5 className="text-uppercase mb-0">Social Link</h5>
                  </div>
                  <Card className="cardBox p-5">
                    <Form>
                      <InputGroup className="mb-4">
                        <Form.Control
                          placeholder="Recipient's username"
                          aria-label="Recipient's username"
                          aria-describedby="basic-addon2"
                          className=""
                          value={`${config.linkUrl}${loginDetails.address}`}
                        />
                        <Button
                          variant="outline-secondary"
                          id="button-addon2"
                          className="text-primary bg-transparent innerBtn px-4"
                          onClick={(e) => {
                            copyText(e);
                          }}
                        >
                          Copy
                        </Button>
                      </InputGroup>

                      <div className="">
                        <Button
                          variant="primary"
                          className="px-5"
                          onClick={handleShow}
                        >
                          Social Link
                        </Button>
                      </div>
                    </Form>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Box>
      </Box>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header className="border-0" closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-5">
          <div className="text-center" style={{ marginTop: "-50px" }}>
            <div className="modal-p-logo">
              <ul>
                {socialLinksAndNames.length > 0 &&
                  socialLinksAndNames.map((item) => (
                    <li>
                      <a
                        href={`${item.link}`}
                        target="_blank"
                        className="text-decoration-none"
                      >
                        <img
                          src={`assets/images/icon/social-media/${item.name}.png`}
                          width={`70px`}
                          style={{ filter: "drop-shadow(0px 0px 0px #4444dd)" }}
                        />
                        <h5 className="text-white mt-2 mb-0 fw-lighter">
                        {item.name}
                        </h5>
                      </a>
                    </li>
                  ))}

              </ul>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Sociallink;
