import React, { useState, useMemo, useEffect } from "react";
import {
    Container,
    Row,
    Col,
    Card,
    Table,
    Form,
    InputGroup,
    Button
} from "react-bootstrap";
import Sidebar from "../Sidebar";
import Box from '@mui/material/Box';
import "../css/spacewall.css"
import "../css/spaceverification.css"
import Select from 'react-select'
import countryList from 'react-select-country-list'
import { flavourOptions } from '../../../data.ts';
import chroma, { random } from 'chroma-js';
import { getCountryList, updateUserDetails, getUserDetailById } from '../../utils/apiServices.js'
import { useDispatch, useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";



const Profile = () => {
    const loginDetails = useSelector((state) => state.auth.LoginDetails[0]);
  const AuthToken = useSelector((state) => state.auth.AuthToken);

    const [formData, setFormData] = useState({ name: '', email: '', mobile: '', countryId: '', DOB: '' })
    const options = useMemo(() => countryList().getData(), [])
    let [country, setCountry] = useState([])
    const [user, setUser] = useState([])
    const [validationErrors, setValidationErrors] = useState({
        name: '', email: '', mobile: '', countryId: '', DOB: ''
    });

    const currentDate = new Date().toISOString().split('T')[0]; // Get current date in "YYYY-MM-DD" format

    useEffect(() => {
        fetchCountries()
        fetchUserDetail()
    }, [])

    const fetchCountries = async () => {
        let res = await getCountryList()
        console.log('res', res.data.data)
        if (res.data.success) {
            let data = res.data.data
            setCountry(data)
        }
    }

    const fetchUserDetail = async () => {
        let res = await getUserDetailById({ id: loginDetails.id },AuthToken)
        if (res.data.success) {
            let data = res.data.data
            setFormData(
                ...data
            )
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    const onSubmit = async () => {
        try {
            const errors = {};
            if (!formData.name) {
                errors.name = 'Name is required';
            }
            if (!formData.email) {
                errors.email = 'Email is required';
            }
            if (!formData.mobile) {
                errors.mobile = 'Phone Number is required';
            }
            if (!formData.DOB) {
                errors.DOB = 'Date Of Birth is required';
            }
            if (!formData.countryId) {
                errors.countryId = 'Country is required';
            }


            if (Object.keys(errors).length > 0) {
                setValidationErrors(errors);
                return;
            }

            formData.id = loginDetails.id
            const res = await updateUserDetails(formData);
            if (res.data.success) {
                toast.success(res.data.msg);
                fetchUserDetail()
            } else {
                toast.error(res.data.msg);
            }
        } catch (error) {
            console.error("Error", error);
        }
    }

    return (
        <>
            <Toaster />
            <Box sx={{ display: '-webkit-box' }} className="dashboard">
                <Sidebar />
                <Box component="main" sx={{ flexGrow: 1, p: 3 }} style={{ marginTop: '74px', }} className="text-white mainBox">
                    <Row>
                        <Col lg={12} className="mb-4 mt-5 pt-5">
                            <Row className="justify-content-center">
                                <Col lg={7}>
                                    <div className="heading mb-3">
                                        <h5 className="text-uppercase mb-0">Get verified with Personal Information</h5>
                                    </div>
                                    <Card className="cardBox p-5">
                                        <Form>

                                            <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                                                <Form.Control type="text" placeholder="Name" name="name" onChange={handleChange} value={formData.name} />
                                                <span className="text-red-500 text-sm">{validationErrors.name}</span>
                                            </Form.Group>

                                            <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                                                <Form.Control type="email" placeholder="Email Address" name="email" onChange={handleChange} value={formData.email} /> <span className="text-red-500 text-sm">{validationErrors.email}</span>
                                            </Form.Group>

                                            <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                                                <Form.Control type="number" placeholder="Phone Number" name="mobile" onChange={handleChange} value={formData.mobile} />
                                                <span className="text-red-500 text-sm">{validationErrors.mobile}</span>
                                            </Form.Group>

                                            <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                                                <Form.Control type="date" placeholder="Date of Birth" name="DOB" onChange={handleChange} value={formData.DOB} max={currentDate}/>
                                                <span className="text-red-500 text-sm">{validationErrors.DOB}</span>
                                            </Form.Group>

                                            <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                                                <Form.Select aria-label="Default select example" name="countryId" onChange={handleChange} value={formData.countryId}>
                                                    <option>Select Country</option>
                                                    {country.map((item) => (
                                                        <option key={item.id} value={item.id}>
                                                            {item.name}
                                                        </option>
                                                    ))}
                                                </Form.Select>
                                            </Form.Group>

                                            <div className="text-right">
                                                <Button variant="primary" className="px-5" onClick={onSubmit}>Submit</Button>
                                            </div>
                                        </Form>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Box>
            </Box>
        </>
    );
};

export default Profile;