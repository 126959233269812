import React, { useState, useMemo } from "react";

import {
    Container,
    Row,
    Col,
    Card,
    Table,
    Form,
    InputGroup,
    Button,
    Modal
} from "react-bootstrap";
import Sidebar from "../Sidebar/index.jsx";
import Box from '@mui/material/Box';
import "../css/spacewall.css"
import "../css/spaceverification.css"
import "../css/emailScript.css"
import Select from 'react-select'
import countryList from 'react-select-country-list'
import { flavourOptions } from '../../../data.ts';
import chroma from 'chroma-js';
import { FaPlus } from "react-icons/fa6";
import {scriptEmailSend} from "../../utils/apiServices.js"
import { useDispatch, useSelector } from "react-redux"
import toast, { Toaster } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";


const EmailscriptView = () => {

    const loginDetails = useSelector((state) => state.auth.LoginDetails[0]);
    // ----------------
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);

    const handleClose = () => setShow(false);
    let { id } = useParams();



    const [email,setEmail]=useState('')
    const [name,setName]=useState('')
    const [emailErrors,setEmailError]=useState('')
    const [nameErrors,setNameError]=useState('')
    const changeHandlerName = e => {
       
        setNameError("")
        setName(e.target.value)
    }

    const changeHandlerEmail = e => {
        setEmailError("")
        setEmail(e.target.value)
    }

    const validations=()=>{
        let emailError='';
        let nameError='';
        if (!email) {
            emailError = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            emailError = 'Email is invalid';
        }
    
        // Name validation
        if (!name) {
            nameError = 'Name is required';
        }

        if(emailError!=''||nameError!=''){
            setEmailError(emailError)
            setNameError(nameError)
            return false
        }
        return true
    }
    const send=async(e)=>{
        e.preventDefault();
        try{

            let validation = validations()

            if(!validation){
                return 
            }

            let payload={
                id:loginDetails.id,
                name:name,
                email:email,
                mailType:id
            }
            let result =await scriptEmailSend(payload)
            if(result.data.success){
                toast.success(result.data.msg)
            }else{
                toast.error(result.data.msg)
            }

        }catch(error){
            console.log(error);
        }
    }

    return (
        <>
        <Toaster/>
            <Box sx={{ display: '-webkit-box' }} className="dashboard">
                <Sidebar />
                <Box component="main" sx={{ flexGrow: 1, p: 3 }} style={{ marginTop: '74px', }} className="text-white mainBox">
                    <Row>

                        <Col lg={12} className="">

                            <div className="heading mb-2">
                                <h5 className="text-uppercase mb-0">Email Script & Invite</h5>
                            </div>
                            <Card className="overflow-hidden">

                                <Row>
                                    
                                    <Col lg={8}>

                                    </Col>
                                    <Col lg={6}>
                                        <Card className="cardBox p-4 ">
                                          
                                            <h5>Receiver's name</h5>
                                            <InputGroup className="mb-3">
                                                <Form.Control
                                                    placeholder="Enter Receiver's name"
                                                    aria-label="Enter Receiver's namee"
                                                    aria-describedby="basic-addon2"
                                                    className="me-4"
                                                    name="name"
                                                    value={name}
                                                    onChange={e=>{changeHandlerName(e)}}
                                                />
                                            
                                            </InputGroup>
                                            {nameErrors!=''&&(
                                                <span style={{color:"red"}}>{nameErrors}</span>
                                              )}
                                            <h5>Reciever's email</h5>
                                            <InputGroup className="mb-3">
                                                <Form.Control
                                                    placeholder="Reciever's email"
                                                   // aria-label="Enter Script Name"
                                                  //  aria-describedby="basic-addon2"
                                                    className="me-4"
                                                    name="email"
                                                    rows={8}
                                                    value={email}
                                                    onChange={e=>{changeHandlerEmail(e)}}
                                                />
                                             
                                            </InputGroup>
                                            {emailErrors!=''&&(
                                                <span style={{color:"red"}}>{emailErrors}</span>
                                              )}
                                        <Button variant="outline-primary" className="px-5 btn-sm" onClick={e=>{send(e)}}>Send</Button>

                                        </Card>
                                    </Col>
                                    {/* <Col lg={6} className="mb-4">
                                        <div className="uploadImage mb-3 sameHeight">
                                            <div>
                                                <FaPlus fill="#E1B028" />
                                            </div>
                                        </div>
                                    </Col> */}
                                   
                                </Row>
                            </Card>
                        </Col>

                    </Row>


                </Box>
            </Box>
            {/* ----------Script Modal start----------- */}
            <Modal show={show} onHide={handleClose} centered className="text-white">
                <Modal.Header closeButton className="border-dark">
                    <Modal.Title>Name of Script1</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3 border-dark" controlId="exampleForm.ControlTextarea1">
                        <Form.Label>Description</Form.Label>
                        <Form.Control as="textarea" rows={3} />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer className="border-dark">
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* --------------------- */}
            <Modal show={show2} onHide={handleClose} centered className="text-white">
                <Modal.Header closeButton className="border-dark">
                    <Modal.Title>Name of Script2</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Label>Name Of Srcipt</Form.Label>
                                                <Form.Control
                                                    placeholder="Enter Script Name"
                                                    aria-label="Enter Script Name"
                                                    aria-describedby="basic-addon2"
                                                    className="me-4"
                                                />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer className="border-dark">
                   
                    <Button variant="primary" onClick={handleClose}>
                       Save
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    );
};

export default EmailscriptView;