import React from "react";

const Spacetool = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                <path d="M17.7649 18.6665C17.108 18.6665 16.5444 19.0726 16.3112 19.6469H14.9414V20.8234H16.3112C16.5444 21.3976 17.108 21.8038 17.7649 21.8038C18.6299 21.8038 19.3336 21.1001 19.3336 20.2351C19.3336 19.3702 18.6299 18.6665 17.7649 18.6665Z" fill="#E1B028" />
                <path d="M20.51 15.3332C20.2522 15.3332 20.0089 15.3961 19.7941 15.5068L18.3376 14.2931C18.2319 14.205 18.0986 14.1567 17.961 14.1567H14.9414V15.3332H17.748L19.0251 16.3974C18.9711 16.5558 18.9414 16.7254 18.9414 16.9018C18.9414 17.7668 19.6451 18.4705 20.51 18.4705C21.375 18.4705 22.0787 17.7668 22.0787 16.9018C22.0787 16.0369 21.375 15.3332 20.51 15.3332Z" fill="#E1B028" />
                <path d="M20.51 10.4312C19.8531 10.4312 19.2895 10.8373 19.0563 11.4115H14.9414V12.588H19.0563C19.2895 13.1623 19.8531 13.5684 20.51 13.5684C21.375 13.5684 22.0787 12.8647 22.0787 11.9998C22.0787 11.1348 21.375 10.4312 20.51 10.4312Z" fill="#E1B028" />
                <path d="M18.3376 9.70667L19.7941 8.49291C20.0089 8.60365 20.2522 8.66655 20.51 8.66655C21.375 8.66655 22.0787 7.96287 22.0787 7.09792C22.0787 6.23298 21.375 5.5293 20.51 5.5293C19.6451 5.5293 18.9414 6.23298 18.9414 7.09792C18.9414 7.27439 18.9711 7.44392 19.0251 7.60232L17.748 8.66655H14.9414V9.84302H17.961C18.0986 9.84302 18.2319 9.79479 18.3376 9.70667Z" fill="#E1B028" />
                <path d="M17.7649 5.33354C18.6299 5.33354 19.3336 4.62986 19.3336 3.76492C19.3336 2.89998 18.6299 2.19629 17.7649 2.19629C17.108 2.19629 16.5444 2.60241 16.3112 3.17668H14.9414V4.35315H16.3112C16.5444 4.92743 17.108 5.33354 17.7649 5.33354Z" fill="#E1B028" />
                <path d="M7.88232 12C7.88232 14.2704 9.7295 16.1176 12 16.1176C12.6311 16.1176 13.2295 15.9747 13.7647 15.7198V8.28017C13.2295 8.02527 12.6311 7.88232 12 7.88232C9.7295 7.88232 7.88232 9.7295 7.88232 12Z" fill="#E1B028" />
                <path d="M13.1765 2H10.8235C10.4987 2 10.2353 2.26337 10.2353 2.58824V3.35392C9.13149 3.57878 8.07518 4.01639 7.13427 4.63859L6.59271 4.09706C6.36298 3.86733 5.99055 3.86733 5.76082 4.09706L4.09706 5.76082C3.86733 5.99051 3.86733 6.36298 4.09706 6.59271L4.63859 7.13427C4.01639 8.07518 3.57878 9.13149 3.35392 10.2353H2.58824C2.26337 10.2353 2 10.4987 2 10.8235V13.1765C2 13.5013 2.26337 13.7647 2.58824 13.7647H3.35392C3.57878 14.8685 4.01639 15.9248 4.63859 16.8658L4.09702 17.4073C3.98671 17.5176 3.92475 17.6673 3.92475 17.8233C3.92475 17.9793 3.98671 18.1288 4.09702 18.2391L5.76082 19.9029C5.99055 20.1327 6.36298 20.1327 6.59271 19.9029L7.13427 19.3614C8.07518 19.9836 9.13149 20.4212 10.2353 20.6461V21.4118C10.2353 21.7366 10.4987 22 10.8235 22H13.1765C13.5013 22 13.7647 21.7366 13.7647 21.4118V16.9913C13.2125 17.1871 12.6185 17.2941 12 17.2941C9.08082 17.2941 6.70588 14.9192 6.70588 12C6.70588 9.08082 9.08082 6.70588 12 6.70588C12.6185 6.70588 13.2125 6.81286 13.7647 7.00871V2.58824C13.7647 2.26337 13.5013 2 13.1765 2Z" fill="#E1B028" />
            </svg>
        </>
    );
};

export default Spacetool;