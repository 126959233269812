import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { getMytransactions } from "../../utils/apiServices.js";
import { Container, Row, Col, Card, Table,Pagination } from "react-bootstrap";
import Sidebar from "../Sidebar";
import Box from "@mui/material/Box";

const Transaction = () => {
  const [transList, settransList] = useState([]);
  const loginDetails = useSelector((state) => state.auth.LoginDetails[0]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 100;
  const [currentItems, setCurrentItems] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  let indexOfLastItem;
  let indexOfFirstItem;
  useEffect(() => {
    getTrxList();
  }, []);

  const getTrxList = async () => {
    let res = await getMytransactions({ userId: loginDetails.userId });
    if (res.data.success) {
      let data = res.data.data;

      setTotalPages(Math.ceil(data.maticWallet.length / itemsPerPage));
    indexOfLastItem = currentPage * itemsPerPage;
    indexOfFirstItem = indexOfLastItem - itemsPerPage;
    setCurrentItems(data.maticWallet.slice(indexOfFirstItem, indexOfLastItem));

      settransList(data);
    }
  };

  const setVizualiseCallMethod123 = (datasssss, pageNumber) => {
    setTotalPages(Math.ceil(datasssss.length / itemsPerPage));
    indexOfLastItem = pageNumber * itemsPerPage;
    indexOfFirstItem = indexOfLastItem - itemsPerPage;
    setCurrentItems(datasssss.slice(indexOfFirstItem, indexOfLastItem));
  };
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    setVizualiseCallMethod123(transList.maticWallet, pageNumber);
  };

  return (
    <>
      <Box sx={{ display: "-webkit-box" }} className="dashboard">
        <Sidebar />
        <Box
          component="main"
          sx={{ flexGrow: 1, p: 3 }}
          style={{ marginTop: "74px" }}
          className="text-white mainBox"
        >
          <Row>
            <Col lg={12} className="">
              <Row>
                <Col lg={12} className="mb-5">
                  <div className="heading mb-3">
                    <h5 className="text-uppercase mb-0">Polygon</h5>
                  </div>
                  <div className="maintablediv h-auto">
                    <Card className="cardBox overflow-hidden">
                      <div className="d-table table-responsive w-100 ">
                        <Table striped hover className="text-center mb-0">
                          <thead>
                            <tr>
                              <th className="text-uppercase">Date</th>
                              <th className="text-uppercase">time</th>
                              <th className="text-uppercase">
                                Type Of Transaction
                              </th>
                              <th className="text-uppercase">Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            {transList &&
                              currentItems &&
                              currentItems.map((item) => (
                                <tr>
                                  <td>
                                    {moment(item.datetime).format("DD/MM/YYYY")}
                                  </td>
                                  <td>
                                    {moment(item.datetime).format("hh:mm:ss")}
                                  </td>
                                  <td>{item.typeName}</td>
                                  <td>${item.amount}</td>
                                </tr>
                              ))}
                          </tbody>
                        </Table>
                      </div>
                    </Card>
                    <div className="mt-3">
                <Pagination className="justify-content-end">
                  <Pagination.First onClick={() => handlePageChange(1)} />
                  <Pagination.Prev
                    onClick={() =>
                      handlePageChange(currentPage > 1 ? currentPage - 1 : 1)
                    }
                  />
                  {/* {[...Array(totalPages)].map((_, idx) => (
                    <Pagination.Item
                      key={idx + 1}
                      active={idx + 1 === currentPage}
                      onClick={() => handlePageChange(idx + 1)}
                    >
                      {idx + 1}
                    </Pagination.Item>
                  ))} */}
                  <Pagination.Item
                    onClick={() => handlePageChange(currentPage)}
                  >
                    {currentPage}
                  </Pagination.Item>
                  <Pagination.Item>...</Pagination.Item>
                  <Pagination.Item onClick={() => handlePageChange(totalPages)}>
                    {totalPages}
                  </Pagination.Item>

                  <Pagination.Next
                    onClick={() =>
                      handlePageChange(
                        currentPage < totalPages ? currentPage + 1 : totalPages
                      )
                    }
                  />
                  <Pagination.Last
                    onClick={() => handlePageChange(totalPages)}
                  />
                </Pagination>
              </div>
                  </div>
                </Col>
                <Col lg={12} className="mb-3">
                  <div className="heading">
                    <h4 className="text-uppercase mb-0">Escrow Wallet</h4>
                  </div>
                </Col>
                <Col lg={4} className="mb-4">
                  <div className="heading mb-3">
                    <h5 className="text-uppercase mb-0">P.i.f. Wallet</h5>
                  </div>
                  <Card className="cardBox overflow-hidden">
                    <div className="maintablediv h-auto">
                      <div className="d-table table-responsive w-100">
                        <Table striped hover className=" mb-0">
                        <colgroup>
                            <col style={{ width:"70%" }} />
                            <col style={{ width:"30%" }} />
                          </colgroup>
                          <thead>
                            <tr>
                              <th className="text-uppercase">
                                Type Of Transaction
                              </th>
                              <th className="text-uppercase">Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            {transList &&
                              transList.maticWallet &&
                              transList.maticWallet.length > 0 &&
                              transList.pifWallet.map((item) => (
                                <tr>
                                  <td className="border-right w-50 text-break">
                                    {item.typeName}
                                  </td>
                                  <td>${item.amount}</td>
                                </tr>
                              ))}
                            {transList &&
                            transList.maticWallet &&
                            transList.maticWallet.length == 0 ? (
                              <tr>
                                <td style={{ width: "200px" }}> </td>
                                <td style={{ width: "300px" }}>
                                  {" "}
                                  No Data Found
                                </td>
                              </tr>
                            ) : (
                              ""
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </Card>
                </Col>
                <Col lg={4} className="mb-4">
                  <div className="heading mb-3">
                    <h5 className="text-uppercase mb-0">Upgrade Wallet</h5>
                  </div>
                  <Card className="cardBox overflow-hidden">
                    <div className="maintablediv h-auto">
                      <div className="d-table table-responsive w-100">
                        <Table striped hover className=" mb-0">
                          <colgroup>
                            <col style={{ width:"70%" }} />
                            <col style={{ width:"30%" }} />
                          </colgroup>
                          <thead>
                            <tr>
                              <th className="text-uppercase">
                                Type Of Transaction
                              </th>
                              <th className="text-uppercase">Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            {transList &&
                              transList.upgradeWallet &&
                              transList.upgradeWallet.length > 0 &&
                              transList.upgradeWallet.map((item) => (
                                <tr>
                                  <td className="border-right w-50 text-break">
                                    {item.typeName}
                                  </td>
                                  <td>${item.amount}</td>
                                </tr>
                              ))}
                            {transList &&
                            transList.upgradeWallet &&
                            transList.upgradeWallet.length == 0 ? (
                              <tr>
                                <td style={{ width: "200px" }}> </td>
                                <td style={{ width: "300px" }}>
                                  {" "}
                                  No Data Found
                                </td>
                              </tr>
                            ) : (
                              ""
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </Card>
                </Col>
                <Col lg={4} className="mb-4">
                  <div className="heading mb-3">
                    <h5 className="text-uppercase mb-0">Re-entry wallet</h5>
                  </div>
                  <Card className="cardBox overflow-hidden ">
                    <div className="maintablediv h-auto">
                      <div className="d-table table-responsive w-100">
                        <Table striped hover className=" mb-0">
                        <colgroup>
                            <col style={{ width:"70%" }} />
                            <col style={{ width:"30%" }} />
                          </colgroup>
                          <thead>
                            <tr>
                              <th className="text-uppercase">
                                Type Of Transaction
                              </th>
                              <th className="text-uppercase">Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            {transList &&
                              transList.reEntryWallet &&
                              transList.reEntryWallet &&
                              transList.reEntryWallet.map((item) => (
                                <tr>
                                  <td className="border-right w-50 text-break">
                                    {item.typeName}
                                  </td>
                                  <td>${item.amount}</td>
                                </tr>
                              ))}
                            {transList &&
                            transList.reEntryWallet &&
                            transList.reEntryWallet.length == 0 ? (
                              <tr>
                                <td style={{ width: "200px" }}> </td>
                                <td style={{ width: "300px" }}>
                                  {" "}
                                  No Data Found
                                </td>
                              </tr>
                            ) : (
                              ""
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Box>
      </Box>
    </>
  );
};

export default Transaction;
