import React, { useState } from "react";
import config from '../../../../src/coreFiles/config';
import {
    Container,
    Row,
    Col,
    Card,
    Table,
    Form,
    InputGroup,
    Button
} from "react-bootstrap";
import Sidebar from "../Sidebar";
import Box from '@mui/material/Box';
import "../css/spacewall.css"
import "../css/spacetool.css"
import "../css/spacewallupload.css"
import { FaPlus } from "react-icons/fa6";
import { MdOutlineFileDownload } from "react-icons/md";



const Spacetool = () => {


    return (
        <>
            <Box sx={{ display: '-webkit-box' }} className="dashboard">
                <Sidebar />
                <Box component="main" sx={{ flexGrow: 1, p: 3 }} style={{ marginTop: '74px', }} className="text-white mainBox">
                    <Row>

                        <Col lg={12} className="">

                            <div className="heading mb-2">
                                <h5 className="text-uppercase mb-4">SPACE TOOL</h5>
                            </div>
                            <Row>
                                <Col lg={12} className="mb-4">
                                    <Card className="overflow-hidden cardBox p-4 sameHeight">
                                        <div className="list-btn">
                                            <Row>
                                                <Col xl={3} lg={4} md={4} className="mb-3">
                                                      <a target="_blank" href={`${config.baseUrl}Hi5PowerPoint`}><Button variant="outline-primary" className="h-auto ml-1 w-100"> PPT</Button></a>
                                                </Col>
                                                <Col xl={3} lg={4} md={4} className="mb-3"><a target="_blank" href={`${config.baseUrl}Emailscript`}><Button variant="outline-primary" className="h-auto ml-1 w-100"> Script</Button></a></Col>
                                                <Col xl={3} lg={4} md={4} className="mb-3"><a target="_blank" href={`${config.baseUrl}Leaflet`}><Button variant="outline-primary" className="h-auto ml-1 w-100"> Leaflet</Button></a></Col>
                                                <Col xl={3} lg={4} md={4} className="mb-3"><a target="_blank" href={`${config.baseUrl}miniVideos`}><Button variant="outline-primary" className="h-auto ml-1 w-100"> Mini Videis</Button></a></Col>

                                                <Col xl={3} lg={4} md={4} className="mb-3"><a target="_blank" href={`${config.baseUrl}Sociallink`}><Button variant="outline-primary" className="h-auto ml-1 w-100"> Social Link</Button></a></Col>
                                                <Col xl={3} lg={4} md={4} className="mb-3"><a target="_blank" href={`${config.baseUrl}LoyaltyItems`}><Button variant="outline-primary" className="h-auto ml-1 w-100"> Loyalty Item</Button></a></Col>
                                            </Row>
                                           
                                        </div>
                                    </Card>
                                </Col>

                            </Row>
                        </Col>

                    </Row>

                </Box>
            </Box>


        </>
    );
};

export default Spacetool;
