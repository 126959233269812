import React, { useState } from "react";

import {
    Container,
    Row,
    Col,
    Card,
    Table,
    Form,
    InputGroup,
    Button,
    Accordion
} from "react-bootstrap";
import Sidebar from "../Sidebar";
import Box from '@mui/material/Box';
import "../css/spacewall.css"
import '../css/chat.css'



const Spacebothelp = () => {


    return (
        <>
            <Box sx={{ display: '-webkit-box' }} className="dashboard">
                <Sidebar />
                <Box component="main" sx={{ flexGrow: 1, p: 3 }} style={{ marginTop: '74px', }} className="text-white mainBox">
                    <Row>

                        <Col lg={12} className="mb-4 mt-5 pt-5">
                            <Row className="justify-content-center">
                                <Col lg={10}>
                                    <div className="heading mb-3">
                                        <h5 className="text-uppercase mb-0">Space Bot Help</h5>
                                    </div>
                                    <Card className="cardBox overflow-hidden p-0">
                                        <div className="chat-accordion">
                                            <Accordion defaultActiveKey="0">
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header>we are here to help</Accordion.Header>
                                                    <Accordion.Body className="pt-5">

                                                        <h5>Hi5 Space Customer Care</h5>

                                                        <div className="messaging">
                                                            <div className="inbox_msg">

                                                                <div className="mesgs">
                                                                    <div className="msg_history">
                                                                        <div className="incoming_msg">
                                                                            <div className="incoming_msg_img"> <img src="assets/images/robot.png" alt="robot" /> </div>
                                                                            <div className="received_msg">
                                                                                <div className="received_withd_msg">
                                                                                    <p>Test which is a new approach to have all
                                                                                        solutions</p>
                                                                                    <span className="time_date"> 11:01 AM    |    June 9</span></div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="outgoing_msg">
                                                                            <div className="sent_msg">
                                                                                <p>Test which is a new approach to have all
                                                                                    solutions</p>
                                                                                <span className="time_date"> 11:01 AM    |    June 9</span> </div>
                                                                        </div>
                                                                        <div className="incoming_msg">
                                                                            <div className="incoming_msg_img"> <img src="assets/images/robot.png" alt="robot" /> </div>
                                                                            <div className="received_msg">
                                                                                <div className="received_withd_msg">
                                                                                    <p>Test, which is a new approach to have</p>
                                                                                    <span className="time_date"> 11:01 AM    |    Yesterday</span></div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="outgoing_msg">
                                                                            <div className="sent_msg">
                                                                                <p>Apollo University, Delhi, India Test</p>
                                                                                <span className="time_date"> 11:01 AM    |    Today</span> </div>
                                                                        </div>
                                                                        <div className="incoming_msg">
                                                                            <div className="incoming_msg_img"> <img src="assets/images/robot.png" alt="sunil" /> </div>
                                                                            <div className="received_msg">
                                                                                <div className="received_withd_msg">
                                                                                    <p>We work directly with our designers and suppliers,
                                                                                        and sell direct to you, which means quality, exclusive
                                                                                        products, at a price anyone can afford.</p>
                                                                                    <span className="time_date"> 11:01 AM    |    Today</span></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="type_msg">
                                                                        <div className="input_msg_write">
                                                                            <input type="text" className="write_msg" placeholder="Type a message" />
                                                                            <button className="msg_send_btn" type="button"><svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                                                                <path d="M1 10L20 1L11 20L9 12L1 10Z" fill="#1B1915" stroke="#1B1915" stroke-linecap="round" stroke-linejoin="round" />
                                                                            </svg></button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>


                                                    </Accordion.Body>
                                                </Accordion.Item>

                                            </Accordion>

                                        </div>
                                    </Card>
                                    <div className=" mt-4">
                                        
                                        <div className="received_msg" style={{padding: "0px 20px 0px 0px"}}>
                                            <div className="received_withd_msg w-100 text-black">
                                                <p style={{padding: "15px 10px 15px 12px"}}>Hello Buddy! Welcome to our website, is there anything i can help you with</p>
                                                </div>
                                        </div>
                                        <div className="incoming_msg_img"> <img src="assets/images/robot2.png" alt="robot" /> </div>
                                    </div>
                                </Col>
                            </Row>

                        </Col>


                    </Row>
                </Box>
            </Box>


        </>
    );
};

export default Spacebothelp;
