import React, { useState, useEffect } from "react";

import {
  Container,
  Row,
  Col,
  Card,
  Table,
  Form,
  InputGroup,
  Button,
  Modal
} from "react-bootstrap";
import Sidebar from "../Sidebar";
import Box from "@mui/material/Box";
import "../css/spacewall.css";
import "../css/spacewallupload.css";
import { FaPlus } from "react-icons/fa6";
import { MdOutlineFileDownload } from "react-icons/md";
import { getAllPpts } from "../../utils/apiServices";
import config from "../../../coreFiles/config";
import axios from "axios";
import PDFViewer from "./PdfViewr"
const Hi5PowerPoint = () => {
  const [pptData, setPptData] = useState([]);

  useEffect(() => {
    fetchPptData();
  }, []);

  const fetchPptData = async () => {
    try {
      let result = await getAllPpts();
      if (result.data.success) {
        setPptData(result.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const downloadMethod = async (e, imageName) => {
    e.preventDefault();
    console.log("downloadMethod")
    try {
      const response = await axios.post(`${config.ApiURL}pptPdfDownload`, { fileName: imageName }, {
        responseType: 'blob' // Specify responseType as 'blob' to receive binary data
      });

      if (response.status === 200) {
        // Create a temporary URL for the PDF blob
        const url = window.URL.createObjectURL(new Blob([response.data]));

        // Create a temporary link element
        const a = document.createElement('a');
        a.href = url;
        a.download = imageName.replace('.jpg', '.pdf'); // Set the download attribute to the PDF file name
        a.click();

        // Clean up by revoking the temporary URL
        window.URL.revokeObjectURL(url);
      } else {
        console.error('Failed to download PDF:', response.statusText);
      }

    } catch (error) {
      console.log(error)
    }
  }


  const [selectedPPT, setSelectedPPT] = useState(null);
  const [model, setModel] = useState(false)
  // Function to handle opening the PDF
  const openPDF = (pptName) => {
    setModel(true)
    setSelectedPPT(pptName);
  };

  // Function to close the PDF viewer
  const closePDF = () => {
    setModel(false)

    setSelectedPPT(null);
  };


  return (
    <>
      <Box sx={{ display: "-webkit-box" }} className="dashboard">
        <Sidebar />
        <Box
          component="main"
          sx={{ flexGrow: 1, p: 3 }}
          style={{ marginTop: "74px" }}
          className="text-white mainBox"
        >
          <Row>
            <Col lg={12} className="">
              <div className="heading mb-2">
                <h5 className="text-uppercase mb-0">Space Tool</h5>

                <p>Hi5 Power Point Presentation</p>
              </div>
              <Row>
                {pptData.length > 0 &&
                  pptData.map((item) => (
                    <Col lg={6} className="mb-4">
                      <Card className="overflow-hidden ">
                        <div className="uploadImage sameHeight mb-3">
                          <div className="text-center">
                            <h4 className="fw-lighter">{item.pptName}</h4>
                            <embed src={`${config.ApiURL}pptPdf/${item.ppt}`} type="application/pdf" width="100%" height="100%" />
                          </div>
                        </div>
                        <div className="">
                          <Button
                            variant="outline-primary"
                            className="h-auto ml-1 me-3"
                            onClick={() => openPDF(item.ppt)}
                          >
                            {" "}
                            View
                          </Button>
                          <Button
                            variant="outline-primary"
                            className="h-auto ml-1 me-3 Downloadbtn" onClick={e => { downloadMethod(e, item.ppt) }}
                          >
                            <MdOutlineFileDownload fill="#E1B028" />
                            &nbsp; Download
                          </Button>
                        </div>
                      </Card>
                    </Col>
                  ))}

              </Row>
            </Col>
          </Row>
        </Box>
      </Box>

      <Modal show={model} onHide={closePDF} centered className="d-modal">
        <Modal.Header closeButton className="py-2">
          <Modal.Title className="fs-5">
            PPT
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0 mb-0 overflow-hidden">
          {selectedPPT && (
            <PDFViewer
               width="100%"
              pdfName={`${config.ApiURL}pptPdf/${selectedPPT}`}
            />
          )}
        </Modal.Body>

      </Modal>



    </>
  );
};

export default Hi5PowerPoint;
