import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Container,
    Row,
    Col,
    Card,
    Table,
    Form,
    InputGroup,
    Button
} from "react-bootstrap";
import Sidebar from "../Sidebar";
import { sendReferralEmail } from '../../utils/apiServices.js'
import Box from '@mui/material/Box';
import "../css/spacewall.css"
import copy from "copy-to-clipboard";
import toast, { Toaster } from 'react-hot-toast';
import config from "../../../coreFiles/config.jsx"

const Referral = () => {

    const loginDetails = useSelector((state) => state.auth.LoginDetails[0]);
    const [Email, setEmail] = useState('')

    const copyText = (e, address) => {
        copy(address);
        toast.success(`You have copied "${address}"`);
    }

    const sendEmail = async (e) => {

        e.preventDefault(); // Prevent default form submission behavior if used in a form

    // Regular expression for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Check if the email matches the regex
    if (!emailRegex.test(Email)) {
        toast.error('Please enter a valid email address.');
        return; // Stop execution if the email is not valid
    }

try{
        let res = await sendReferralEmail({ Email: Email, socialLink: `${config.linkUrl}${loginDetails.address} `,id:loginDetails.id })
        if (res.data.success) {
            toast.success(res.data.msg)
            setEmail('')
        }else{
            toast.error(res.data.msg)
        }
    }catch(error){
        console.log(error)
    }
    }

    return (
        <>
         <Toaster />
            <Box sx={{ display: '-webkit-box' }} className="dashboard">
                <Sidebar />
               
                <Box component="main" sx={{ flexGrow: 1, p: 3 }} style={{ marginTop: '74px', }} className="text-white mainBox">
                    <Row>

                        <Col lg={12} className="mb-4 mt-5 pt-5">
                            <Row className="justify-content-center">
                                <Col lg={10}>
                                    <div className="heading mb-3">
                                        <h5 className="text-uppercase mb-0">Your Space Referral Link</h5>
                                    </div>
                                    <Card className="cardBox overflow-hidden p-5">
                                        <Form>
                                           {loginDetails &&
                                            <InputGroup className="mb-3">
                                                <Form.Control
                                                    placeholder="Recipient's username"
                                                    aria-label="Recipient's username"
                                                    aria-describedby="basic-addon2"
                                                    className=""
                                                    value={`${config.linkUrl}${loginDetails.address}`}
                                                />
                                                <Button onClick={e => copyText(e, `${config.linkUrl}${loginDetails.address}`)} variant="outline-secondary" id="button-addon2" className="text-primary bg-transparent innerBtn px-4">
                                                    Copy
                                                </Button>
                                            </InputGroup>}
                                            {/* <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <Form.Control type="email" placeholder="Email Address" value={Email} onChange={e => setEmail(e.target.value)} />
                                            </Form.Group> */}
                                            {/* <Button onClick={e => sendEmail(e)} variant="primary">Send Your Invite Link To Your Friends</Button> */}


                                        </Form>
                                    </Card>
                                </Col>
                            </Row>

                        </Col>


                    </Row>
                </Box>
            </Box>


        </>
    );
};

export default Referral;
