import React from "react";
import Header from "../directives/Header";
import Footer from "../directives/Footer";
import "../componentCss/home.css";
import "../componentCss/login.css"
import {
    Container,
    Row,
    Col,
    Button,
    Card,
    Form
} from "react-bootstrap";
import { Zoom, Fade, Roll, Slide } from 'react-reveal';




const Login = () => {

    return (
        <>
            <Header />
            <section className="login bg-galaxy py-lg-5 pt-4 mt-5 position-relative">
                {/* <div className="banner-bg">
                    <img src="assets/images/globe.png"/>
                </div> */}
                <Container className="pt-4">
                    <Row className="justify-content-center">
                        <Col lg={8} md={8} className="bannerForm">
                            <Zoom>
                                <div className="wrap d-md-flex">
                                <div className="login-wrap p-4 p-md-5">
                                    <h2>Login</h2>
                                    <p>Secure your journey: Enter the gateway to your account.</p>
                                        <Form className="signin-form">
                                            fdsfsd
                                        </Form>
                                    </div>
                                    <div class="img" style={{backgroundImage: "url()",backgroundSize: "contain", backgroundRepeat: "no-repeat"}}>
                                    </div>
                                    

                                </div>
                            </Zoom>

                        </Col>
                    </Row>
                </Container>
            </section>



        </>
    );
};

export default Login;
