import * as ACTIONTYPES from '../actionTypes'

export const initialState = {
    LoginDetails: {},
    AuthToken: '',
    isLogin: false,
};
export default function auth(state = initialState, action) {

    switch (action.type) {
        case ACTIONTYPES.USERLOGIN:
            return {
                ...state,
                LoginDetails: action.payload,
                AuthToken: action.token,
                isLogin: action.isLogin
            };
            case ACTIONTYPES.isVerified:
                return {
                    ...state,
                    isVerified: action.payload
                };
        case ACTIONTYPES.walletConnnected:
            return {
                ...state,
                walletConnnected: action.payload
            };
        case ACTIONTYPES.USERLOGOUT:
                return {
                    ...state,
                    LoginDetails: {},
                    AuthToken: '',
                    isLogin: false
                };
    

        default:
            return state;
    }
}
