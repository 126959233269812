import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import config from './coreFiles/config';
import Home from './component/Home';
import Login from './component/Login';
import About from './component/About';
import Feature from './component/Feature';
import Spacewall from './component/Spacewall';
import Faq from './component/Faq';
import Termscondition from './component/Termscondition';
// -----------------------------
import Dashboard from './component/Dashboard';
import Spacenetwork from './component/Dashboard/Spacenetwork';
import MatrixMembers from './component/Dashboard/Spacenetwork/matrixMembsers';

import Spacedonation from './component/Dashboard/Spacedonation';
import Invitedonation from './component/Dashboard/Invitedonation';
import Directdonation from './component/Dashboard/Directdonation';
import Reciprocaldonation from './component/Dashboard/Reciprocaldonation';
import Referral from './component/Dashboard/Referral';
import Spaceverification from './component/Dashboard/Spaceverification';
import Profile from './component/Dashboard/Profile/index';
import Transaction from './component/Dashboard/Transaction';
import Referraltree from './component/Dashboard/Referraltree';
import Spacewallupload from './component/Dashboard/Spacewallupload';
import Uploads from './component/Dashboard/Spacewallupload/Upload';
import Activities from './component/Dashboard/Spacewallupload/Activities';
import Letsdonate from './component/Dashboard/Spacewallupload/Letsdonates';
import Spacetool from './component/Dashboard/Spacetool';
import Leaflet from './component/Dashboard/Leaflet';
import MiniVideos from './component/Dashboard/MiniVideos';
import LoyaltyItems from './component/Dashboard/LoyaltyItems';
import Sociallink from './component/Dashboard/Sociallink';
import Emailscript from './component/Dashboard/Emailscript';
import EmailscriptView from './component/Dashboard/Emailscript/emailScriptView';
import Hi5PowerPoint from './component/Dashboard/Hi5PowerPoint';
import Spacebothelp from './component/Dashboard/Spacebothelp';
import Bothelp from './component/Dashboard/Spacebothelp/Bothelp';
import Teammateshelp from './component/Dashboard/Spacebothelp/Teammateshelp';
import Setting from './component/Dashboard/Setting';
import Piechart from './component/Dashboard/piechart';
import AdminSidebar from './component/Dashboard/AdminSidebar';
import SpaceInvite from './component/Dashboard/SpaceInvite';
import Spaceconnect from './component/Dashboard/Spaceconnect';




function App() {
  return (
    <>

      <BrowserRouter>
        <Routes>
          <Route path={`${config.baseUrl}`} element={<Home />} />
          <Route path={`${config.baseUrl}:referraladdress`} element={<Home />} />

          <Route path={`${config.baseUrl}login`} element={<Login />} />
          <Route path={`${config.baseUrl}about`} element={<About />} />
          <Route path={`${config.baseUrl}feature`} element={<Feature />} />
          <Route path={`${config.baseUrl}spacewall`} element={<Spacewall />} />
          <Route path={`${config.baseUrl}faq`} element={<Faq />} />
          <Route path={`${config.baseUrl}termscondition`} element={<Termscondition />} />
          {/* ---------Dashboard Start------------- */}
          <Route path={`${config.baseUrl}dashboard`} element={<Dashboard />} />
          <Route path={`${config.baseUrl}spaceinvite`} element={<SpaceInvite />} />
          <Route path={`${config.baseUrl}spacenetwork`} element={<Spacenetwork />} />
          <Route path={`${config.baseUrl}MatrixMembers`} element={<MatrixMembers />} />

          <Route path={`${config.baseUrl}spacedonation`} element={<Spacedonation />} />
          <Route path={`${config.baseUrl}invitedonation`} element={<Invitedonation />} />
          <Route path={`${config.baseUrl}directdonation`} element={<Directdonation />} />
          <Route path={`${config.baseUrl}reciprocaldonation`} element={<Reciprocaldonation />} />
          <Route path={`${config.baseUrl}referral`} element={<Referral />} />
          <Route path={`${config.baseUrl}spaceverification`} element={<Spaceverification />} />
          <Route path={`${config.baseUrl}Profile`} element={<Profile />} />
          
          <Route path={`${config.baseUrl}transaction`} element={<Transaction />} />
          <Route path={`${config.baseUrl}referraltree`} element={<Referraltree />} />
          <Route path={`${config.baseUrl}spacewallupload`} element={<Spacewallupload />} />

          <Route path={`${config.baseUrl}upload`} element={<Uploads />} />
          <Route path={`${config.baseUrl}letsdonate`} element={<Letsdonate />} />
          <Route path={`${config.baseUrl}activities`} element={<Activities />} />

          <Route path={`${config.baseUrl}spacetool`} element={<Spacetool />} />
          <Route path={`${config.baseUrl}leaflet`} element={<Leaflet />} />
          <Route path={`${config.baseUrl}miniVideos`} element={<MiniVideos />} />

          <Route path={`${config.baseUrl}loyaltyitems`} element={<LoyaltyItems />} />
          <Route path={`${config.baseUrl}sociallink`} element={<Sociallink />} />
          <Route path={`${config.baseUrl}emailscript`} element={<Emailscript />} />
          <Route path={`${config.baseUrl}emailscriptView/:id`} element={<EmailscriptView />} />

          <Route path={`${config.baseUrl}hi5powerpoint`} element={<Hi5PowerPoint />} />
          <Route path={`${config.baseUrl}spacebothelp`} element={<Spacebothelp />} />
          <Route path={`${config.baseUrl}bothelp`} element={<Bothelp />} />
          <Route path={`${config.baseUrl}teammateshelp`} element={<Teammateshelp />} />
          <Route path={`${config.baseUrl}setting`} element={<Setting />} />
          <Route path={`${config.baseUrl}spaceconnect`} element={<Spaceconnect />} />
          <Route path={`${config.baseUrl}piechart`} element={<Piechart />} />

          <Route path={`${config.baseUrl}adminSidebar`} element={<AdminSidebar />} />

        </Routes>
      </BrowserRouter>

    </>
  );
}

export default App;

