import React, { useState, useEffect } from 'react';
import config from "../../../coreFiles/config";

const PDFViewer = ({ pdfName }) => {
  

  return (
    <div>



      {pdfName && (
        <div className="form-group row mb-1">
          <div className="col-md-12">
            <embed src={pdfName} type="application/pdf" width="100%" height="100%" />
          </div>
        </div>
      )}
    </div>
  );
};

export default PDFViewer;
