import React, { useEffect, useState } from "react";

import {
    Container,
    Row,
    Col,
    Card,
    Table,
    Form,
    Button
} from "react-bootstrap";
import Sidebar from "../../Sidebar";
import Box from '@mui/material/Box';
import Web3ModalProvider from "../../../ConnectWallet/Web3ModalProvider"
import { useAccount } from "wagmi";
import web3Config from "../../../Web3/web3Config";
import {donateUsdt,donatedUsdt} from "../../../Web3/Connector"
import { useWeb3Modal } from "@web3modal/wagmi/react";
import toast, { Toaster } from "react-hot-toast";
import { polygon } from 'wagmi/chains'
import { TailSpin } from "react-loader-spinner";


const Letsdonates = () => {
    const { isConnected, address } = useAccount();
    const { isOpen, open, close } = useWeb3Modal();
    const [sendButtonClicked,setSendButtonClicked] = useState(false)
    const [totalDonation,setTottalDonation]=useState(0)
    const [usdtAmountToDonate,setUsdtAmountToDonate] = useState(0);
    const _useWeb3ModalProvider = Web3ModalProvider();
    const [loader,setLoader]=useState(false)

    useEffect(()=>{
        if(sendButtonClicked && isConnected){
            sendFunction()
        }
       
    },[isConnected])
    
    useEffect(() => {
       // setDefaultChain(polygon);
        donatedUsdts()
      }, []);
        
    const sendFunction=async()=>{
        try{

        }catch(error){
            console.log(error)
        }
    }

    const submit=async(e)=>{
        e.preventDefault();
        try{
       setLoader(true)
            
           if(!sendButtonClicked){
            setSendButtonClicked(true)
           }
            if(!isConnected){
                open()
       setLoader(false)

                return
            }

            let result =await donateUsdt(address,usdtAmountToDonate,_useWeb3ModalProvider);
            if(result.status){
toast.success(result.message)


setLoader(false)

            }else{
                toast.error(result.message)
       setLoader(false)

            }

        }catch(error){
            console.log(error)
            toast.error(error)
       setLoader(false)


        }
    }

  const  donatedUsdts=async()=>{ 
    try{
        setLoader(true)
        let result=  await donatedUsdt(_useWeb3ModalProvider);
       if(result.status){
        setTottalDonation(result.data)
       }
       setLoader(false)
    }catch(error){
        console.log(error)
    }
  }
    return (
        <>
              <Toaster />

            <Box sx={{ display: '-webkit-box' }} className="dashboard">
            { loader &&<div className="mainLoader">
        <TailSpin
          visible={true}
          height="80"
          width="80"
          color="#e1b028"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass=""
        />
      </div>}
                <Sidebar />
                <Box component="main" sx={{ flexGrow: 1, p: 3 }} style={{ marginTop: '74px', }} className="text-white mainBox">

                    <Row>

                        <Col lg={12} className="">
                            <Row>
                                <Col lg={12} className="">
                                    <div className="heading mb-4">
                                        <h5 className="text-uppercase mb-0 text-primary" >SPACE COMMUNITY REWARDS FUND</h5>

                                    </div>
                                    <div className="mb-4">
                                        <p>Please consider supporting our space community's reward fund, your favorite non-governmental organization (NGO), or any humanitarian organization
                                            that plays a significant role in the social development of your community. You can make a small contribution from the benefits you receive through the Hi5
                                            Space Community.</p>

                                    </div>
                                </Col>
                                <Col lg={12} className="mb-4">
                                    <div className="heading mb-4">
                                        <h6 className="text-uppercase mb-0">Hi5 SPACE CAN OFFER YOU A MEANINGFUL PURPOSE IN LIFE WHILE YOU'RE STILL ALIVE, ALLOWING YOU TO BRIGHTEN SOMEONE ELSE’S LIFE.</h6>
                                    </div>
                                    <div className="mb-4">
                                        <p>We extend an invitation to all active donors, encouraging them to contribute a small portion to the Space Reward Fund. Through synergistic efforts, we can
                                            collectively catalyze global transformation by assisting those in need through the Hi5 Space Social Entrepreneurship Program. When everyone gets
                                            involved, we can drive significant positive change worldwide.</p>
                                    </div>
                                    <div className="heading mb-4">
                                        <h5 className="text-uppercase text-primary">LET'S DONATE TO THE SPACE COMMUNITY REWARD FUND.</h5>
                                        <h6 className="mb-0">YOUR SOCIAL RESPONSIBILITY TO THE COMMUNITY</h6>
                                    </div>
                                    <Card className="cardBox p-5">
                                        
                                        <Form className="row align-items-end">
                                            <Form.Group className="col-lg-6" controlId="exampleForm.ControlInput1">
                                                <Form.Label className="text-success b">LET’S DONATE (Enter your preferred amount in USD)</Form.Label>
                                                <Form.Control type="text" placeholder="CHOOSE YOUR AMOUNT IN USD" value={usdtAmountToDonate} onChange={e=>setUsdtAmountToDonate(e.target.value)}  />
                                            </Form.Group>


                                            <div className="col-lg-2">
                                                <Button variant="primary" onClick={e=>{submit(e)}} className="px-5">Submit</Button>
                                            </div>
                                            <div className="col-lg-1">
                                            </div>
                                            <Form.Group className="col-lg-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label className="b">Total donation received</Form.Label>
                                            {console.log(totalDonation)}
                                                <Form.Control type="text" value={totalDonation} />
                                            </Form.Group>


                                        </Form>
                                    </Card>
                                </Col>


                            </Row>


                        </Col>

                    </Row>
                </Box>
            </Box>


        </>
    );
};

export default Letsdonates;
