import React from "react";
// import config from "../../../coreFiles/config";
import { Link } from "react-router-dom";
import { FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { BsTwitterX } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";
import '../../componentCss/footer.css'
import {
  Container,
  Row,
  Col
} from "react-bootstrap";
import config from '../../../coreFiles/config';

const Footer = () => {

  return (
    <>
      {/*------------------------- Header Section Start -------------------- */}



      <footer className="footer_bg py-5 pb-0 pt-3 position-relative">
        <Container>
          <Row>
            <Col xl={12} lg={12} md={12} className="">
              <div className="footer-logo">
                <Link to={`${config.baseUrl}`}>
                  <img src="assets/images/logo.png" width="180px" />
                </Link>
              </div>
              <p className="mt-4 mb-0">Hi5 Space offers unlimited real-time financial opportunities for everyone, presenting a novel approach to
                empower individuals as global social entrepreneurs by enabling them to generate financial resources.
                The aim is to foster an ownership and leadership attitude, encouraging positive change in the world through
                principles of unity, diversity, and equality.</p>
              {/* <p className="mt-4"><span className="text-primary">A new way</span> to make the payments
                easy, reliable and secure.</p> */}
            </Col>
            <Col xl={12} lg={12} md={12} sm={12} xs={12} className="">
              <div className="footer-block">
                <h5 className="mb-3">Useful Links</h5>
                <ul className="mb-4">
                  <li className="d-inline-block me-4"><a href="#" className="text-decoration-none">Content</a></li>
                  <li className="d-inline-block me-4"><a href="#" className="text-decoration-none">How it Works</a></li>
                  <li className="d-inline-block me-4"><a href="#" className="text-decoration-none">Create</a></li>
                  <li className="d-inline-block me-4"><a href="#" className="text-decoration-none">Explore</a></li>
                  <li className="d-inline-block"><a href={`${config.baseUrl}termscondition`} className="text-decoration-none">Terms & Condition</a></li>

                </ul>

              </div>
            </Col>
            {/* <Col xl={1} lg={1}>
            </Col> */}
            {/* <Col xl={10} lg={10} md={12} className="mb-3">
              <Row>
                <Col xl={4} lg={4} md={3} sm={6} xs={6} className="mb-3">
                  <div className="footer-block">
                    <ul>
                      <li><h5>Useful Links</h5></li>
                      <li><a href="#" className="text-decoration-none">Content</a></li>
                      <li><a href="#" className="text-decoration-none">How it Works</a></li>
                      <li><a href="#" className="text-decoration-none">Create</a></li>
                      <li><a href="#" className="text-decoration-none">Explore</a></li>
                      <li><a href={`${config.baseUrl}termscondition`} className="text-decoration-none">Terms & Condition</a></li>

                    </ul>

                  </div>
                </Col>
                <Col xl={4} lg={4} md={3} sm={6} xs={6} className="mb-3">
                  <div className="footer-block">
                    <ul>
                      <li><h5>Community</h5></li>
                      <li><a href="#" className="text-decoration-none">Help Center</a></li>
                      <li><a href="#" className="text-decoration-none">Partners</a></li>
                      <li><a href="#" className="text-decoration-none">Suggestions</a></li>
                      <li><a href="#" className="text-decoration-none">Blog</a></li>
                      <li><a href="#" className="text-decoration-none">Newsletter</a></li>
                    </ul>

                  </div>
                </Col>
                <Col xl={4} lg={4} md={3} sm={6} xs={12} className="mb-3">
                  <div className="footer-block">
                    <ul>
                      <li><h5>Partner</h5></li>
                      <li><a href="#" className="text-decoration-none">Our Partner</a></li>
                      <li><a href="#" className="text-decoration-none">Become a partener</a></li>
                    </ul>

                  </div>
                </Col>
              </Row>
            </Col> */}

          </Row>
        </Container>

        <div className="copyright">
          <Container>
            <Row className="align-items-center">
              <Col lg={12}>
                <hr className="hr-line mt-0" />
              </Col>
              <Col lg={8} className="mb-2">
                <p className="">Copyright@2024 Hi5 Space, All Rights Reserved.</p>
              </Col>
              <Col lg={4} className="mb-2 text-right">
                <div className="social-icon">
                  <ul>
                    <li><FaInstagram /></li>
                    <li><BsTwitterX /></li>
                    <li><FaLinkedinIn /></li>
                    <li><FaFacebookF /></li>
                  </ul>

                </div>
              </Col>
            </Row>

          </Container>
        </div>

      </footer>

      {/*------------------------- Header Section Exit -------------------- */}
    </>
  );
};

export default Footer;
