import React, { useState } from "react";

import {
    Container,
    Row,
    Col,
    Card,
    Table,
    Form,
    InputGroup,
    Button
} from "react-bootstrap";
import Sidebar from "../Sidebar";
import Box from '@mui/material/Box';
import "../css/spacewall.css"
import "../css/spacewallupload.css"
import "../css/spaceconnect.css"
import { FaPlus } from "react-icons/fa6";
import { MdOutlineFileDownload } from "react-icons/md";



const Spaceconnect = () => {


    return (
        <>
            <Box sx={{ display: '-webkit-box' }} className="dashboard">
                <Sidebar />
                <Box component="main" sx={{ flexGrow: 1, p: 3 }} style={{ marginTop: '74px', }} className="text-white mainBox">
                    <Row>

                        <Col lg={12} className="">

                            {/* <div className="heading mb-2">
                                <h5 className="text-uppercase mb-0">Setting</h5>
                            </div> */}
                            <div className="spaceconnect py-lg-0" style={{ backgroundPosition: "bottom" }}>

                <section className="py-lg-0 py-md-0 py-sm-0  pt-0 position-relative">

                    <Container>
                        <Row className="justify-content-center">
                            <Col lg={12}>
                                {/* <h2 className="text-primary mb-3">Space connect</h2> */}
                                <div className="">
                                    <div className="d-flex justify-content-between">
                                       
                                       <div className="w-50 text-start v-line Spacecontent">
                                            <h3>
                                                Space Token
                                            </h3>
                                        </div>
                                        <div className="w-50 text-end Spacecontent">
                                        <h3>
                                            Space Game
                                        </h3>
                                        </div>

                                    </div>
                                    <div className="commingsoon text-center header__center">
                                        <h2 className="mb-0 border border-white p-3 d-inline-block px-5 rounded-5">
                                            Coming Soon
                                        </h2>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                    <div className="w-50 text-start v-line Spacecontent">
                                        <h3>
                                            Space Mall
                                        </h3>
                                        </div>
                                        <div className="w-50 text-end Spacecontent">
                                        <h3>
                                            Space Time
                                        </h3>
                                        </div>

                                    </div>
                                </div>
                               
                            </Col>
                        </Row>
                    </Container>

                </section>
            </div>
                        
                        </Col>

                    </Row>
                   
                </Box>
            </Box>


        </>
    );
};

export default Spaceconnect;
