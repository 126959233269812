// import React, { useState } from "react";

// import {
//     Container,
//     Row,
//     Col,
//     Card,
//     Table,
//     Button,
//     Form
// } from "react-bootstrap";
// import Sidebar from "../../Sidebar";
// import Box from '@mui/material/Box';





// const Upload = () => {


//     return (
//         <>
//             <Box sx={{ display: '-webkit-box' }} className="dashboard">
//                 <Sidebar />
//                 <Box component="main" sx={{ flexGrow: 1, p: 3 }} style={{ marginTop: '74px', }} className="text-white mainBox">

//                     <Row>

//                         <Col lg={12} className="">
//                             <Row>
//                                 <Col lg={12} className="">
//                                     <div className="heading mb-4">
//                                         <h5 className="text-uppercase mb-0">SPACE COMMUNITY REWARDS FUND</h5>

//                                     </div>
//                                     <div className="mb-4">
//                                         <p>The Space Community Reward Fund seems to be a mechanism where members of the Hi5 space community can contribute to a fund that supports
//                                             charitable endeavors within the space community. It's great to see that there's an emphasis on supporting individuals or organizations who have actively
//                                             participated in charity activities themselves or have received donations from Hi5 Space community.</p>
//                                         <p>Every month, starting on the 5th day, the Space Reward Fund Sharing becomes active. The space loop will seamlessly connect these pictures with space
//                                             community reward fund. Activities that create a positive impact on the community stand a higher chance of receiving these reward funds. When the
//                                             collected reward funds hit $500, the smart contract evenly splits 50% of the reward amount among qualified participants and rolls over the remaining 50%
//                                             to the following month.</p>
//                                         <p className="mb-0">In order to qualify for space reward funds, individuals need to showcase an image of their charitable activity on the space wall. This should include the Hi5
//                                             space logo worn on clothing items like shirts, hats, flags, or any other identification associated with the Hi5 space community.</p>
//                                     </div>
//                                 </Col>
//                                 <Col lg={12} className="mb-4">
//                                     <div className="heading mb-4">
//                                         <h5 className="text-uppercase mb-0">Hi5 SPACE SOCIAL ENTREPRENEURSHIP PROGRAM</h5>
//                                     </div>

//                                     <Card className="cardBox p-5">
//                                         <Form className="row">
//                                             <Form.Group className="col-lg-6 mb-4" controlId="exampleForm.ControlInput1">
//                                                 <Form.Label>Name of event (max 5 words)</Form.Label>
//                                                 <Form.Control type="text" placeholder="Name of event"  />
//                                             </Form.Group>
//                                             <Form.Group className="col-lg-6 mb-4" controlId="exampleForm.ControlInput1">
//                                                 <Form.Label>Description (max 30 words)</Form.Label>
//                                                 <Form.Control as="textarea" rows={1}/>
//                                             </Form.Group>
//                                             <Form.Group className="col-lg-6 mb-4" controlId="exampleForm.ControlInput1">
//                                                 <Form.Label>Country</Form.Label>
//                                                 <Form.Select aria-label="Default select example">
//                                                     <option>India</option>
//                                                     <option value="1">Australia</option>
//                                                     <option value="2">Newzealand</option>
//                                                     <option value="3">Indonesia</option>
//                                                 </Form.Select>
//                                             </Form.Group>
//                                             <Form.Group className="col-lg-6 mb-4" controlId="exampleForm.ControlInput1">
//                                                 <Form.Label>Number of participants</Form.Label>
//                                                 <Form.Control type="text" placeholder="Number of participants" />
//                                             </Form.Group>
//                                             <Form.Group className="col-lg-6 mb-4" controlId="exampleForm.ControlInput1">
//                                                 <Form.Label>Date</Form.Label>
//                                                 <Form.Control type="text" placeholder="Number of participants" />
//                                             </Form.Group>
//                                             <Form.Group className="col-lg-6 mb-4" controlId="exampleForm.ControlInput1">
//                                                 <Form.Label>Upload photos</Form.Label>
//                                                 <div><input type="file" placeholder="Upload photos" /></div>
//                                             </Form.Group>


//                                             <div className="text-right">
//                                                 <Button variant="primary" className="px-5">Submit</Button>
//                                             </div>


//                                         </Form>
//                                     </Card>
//                                 </Col>


//                             </Row>


//                         </Col>

//                     </Row>
//                 </Box>
//             </Box>


//         </>
//     );
// };

// export default Upload;


import React, { useEffect, useState } from "react";
import Sidebar from "../../Sidebar";
import Box from '@mui/material/Box';
import {fetchCountriesApi,eventsAddApi} from '../../../utils/apiServices';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import config from "../../../../coreFiles/config";
import toast, { Toaster } from "react-hot-toast";
import "../../css/spaceverification.css";
import { TailSpin } from "react-loader-spinner";

const Upload = () => {
  const [loader,setLoader]=useState(false)

    const [eventName, setEventName] = useState("");
    const [description, setDescription] = useState("");
    const [countries, setCountry] = useState([]);
    const [participants, setParticipants] = useState("");
    const [date, setDate] = useState("");
    const [file, setFile] = useState(null);
    const [error, setError] = useState("");
    const [selectedCountry, setSelectedCountry] = useState('');
    const [errors, setErrors] = useState({});
    let navigate = useNavigate();


    useEffect(()=>{
        fetchCountries()
    },[])

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile && selectedFile.size <= 5 * 1024 * 1024) { // 5 MB limit
            setFile(selectedFile);
            setError("");
        } else {
            setFile(null);
            setError("File size should be less than 5MB");
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try{
        setErrors({});
        setLoader(true)
        // Validation checks
        const errors = {};
        if (!eventName || eventName.split(' ').length > 5) {
            errors.eventName = 'Event name must be 5 words or less';
        }
        if (!description || description.split(' ').length > 30) {
            errors.description = 'Description must be 30 words or less';
        }
        if (selectedCountry === 'Select a country') {
            errors.country = 'Please select a country';
        }
        if (!participants || participants == 0) {
            errors.participants = 'Number of participants cannot be 0';
        }
        if (!date || new Date(date) >= new Date()) {
            errors.date = 'Date must be in the past';
        }

        // If there are any errors, set them and return
        if (Object.keys(errors).length > 0) {
            setErrors(errors);
            return;
        }

        const formData = new FormData();
        formData.append('eventName', eventName);
        formData.append('description', description);
        formData.append('country', selectedCountry);
        formData.append('participants', participants);
        formData.append('date', date);
        formData.append('file', file);

        let result =  await eventsAddApi(formData);
        if (result.data.success) {
            // Delay the navigation by 3 seconds
            toast.success(result.data.message);
            setTimeout(() => {
                let path = `${config.baseUrl}dashboard`;
                navigate(path);
            }, 3000); // 3000 milliseconds = 3 seconds
        }else{
            toast.error(result.data.message);
            setLoader(false)
        }
    }catch(error){
        console.log(error)
        setLoader(false)

    }
    };


    const fetchCountries = async () => {
        try {
            let result  = await fetchCountriesApi()
            console.log(result.data.data)
            //setCountry(["India","Pakistan","Nepal","Bhutan"]);
            setCountry(result.data.data)
        } catch (error) {
          console.error('Error fetching countries:', error);
          return [];
        }
      };

   const handleChange=(e)=>{
   
    setErrors({});


if(e.target.name == "eventName"){
    setEventName(e.target.value)
}else if(e.target.name == "description"){
    setDescription(e.target.value)
}else if(e.target.name == "country"){
    setSelectedCountry(e.target.value);

}else if(e.target.name == "participants"){
    setParticipants(e.target.value)

}else if(e.target.name == "date"){
    setDate(e.target.value)

}
    }
    const today = new Date().toISOString().split('T')[0];
    return (
        <>
           <Toaster/>
            <Box sx={{ display: '-webkit-box' }} className="dashboard">
                <Sidebar />
                { loader &&<div className="mainLoader">
        <TailSpin
          visible={true}
          height="80"
          width="80"
          color="#e1b028"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass=""
        />
      </div>}
                <Box component="main" sx={{ flexGrow: 1, p: 3 }} style={{ marginTop: '74px', }} className="text-white mainBox">
                    <div className="heading mb-4">
                        <h5 className="text-uppercase mb-0 text-primary">SPACE COMMUNITY REWARDS FUND</h5>
                    </div>
                    <div className="mb-4">
                        <p>The Space Community Reward Fund seems to be a mechanism where members of the Hi5 space community can contribute to a fund that supports
                            charitable endeavors within the space community. It's great to see that there's an emphasis on supporting individuals or organizations who have actively
                            participated in charity activities themselves or have received donations from Hi5 Space community.</p>
                        <p>Every month, starting on the 5th day, the Space Reward Fund Sharing becomes active. The space loop will seamlessly connect these pictures with space
                            community reward fund. Activities that create a positive impact on the community stand a higher chance of receiving these reward funds. When the
                            collected reward funds hit $500, the smart contract evenly splits 50% of the reward amount among qualified participants and rolls over the remaining 50%
                            to the following month.</p>
                        <p className="mb-0">In order to qualify for space reward funds, individuals need to showcase an image of their charitable activity on the space wall. This should include the Hi5
                            space logo worn on clothing items like shirts, hats, flags, or any other identification associated with the Hi5 space community.</p>
                    </div>
                    <div className="heading mb-4">
                        <h5 className="text-uppercase mb-0 text-primary">Hi5 SPACE SOCIAL ENTREPRENEURSHIP PROGRAM</h5>
                    </div>
                    <div className="cardBox p-5">
                        <form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-lg-6 mb-4">
                                    <label htmlFor="eventName" >Name of event (max 5 words)</label>
                                    <input type="text" id="eventName" className={`form-control ${errors.eventName ? 'is-invalid' : ''}`} placeholder="Name of event" value={eventName} name="eventName" onChange={(e) => handleChange(e)} required />
                                    {errors.eventName && <span className="invalid-feedback">{errors.eventName}</span>}
                                </div>
                                <div className="col-lg-6 mb-4">
                                    <label htmlFor="description">Description of event(max 30 words)</label>
                                    <textarea id="description" className={`form-control ${errors.description ? 'is-invalid' : ''}`} rows={3} value={description} name="description" onChange={(e) => handleChange(e)} required />
                                    {errors.description && <span className="invalid-feedback">{errors.description}</span>}
                                </div>
                                <div className="col-lg-6 mb-4">
                                <label htmlFor="country">Country</label>
                                    <select id="country" className={`form-control ${errors.country ? 'is-invalid' : ''}`} value={selectedCountry} name="country" onChange={handleChange} required>
                                        <option value="">Select a country</option>
                                        {countries.length > 0 &&
                                        countries.map(country => (
                                            <option key={country} value={country.name}>{country.name}</option>
                                        ))}
                                    </select>
                                    {errors.country && <span className="invalid-feedback">{errors.country}</span>}

                                </div>
                                <div className="col-lg-6 mb-4">
                                    <label htmlFor="participants">Number of participants</label>
                                    <input type="number" id="participants" className={`form-control ${errors.participants ? 'is-invalid' : ''}`} placeholder="Number of participants" value={participants} name="participants" onChange={(e) => handleChange(e)} required />
                                    {errors.participants && <span className="invalid-feedback">{errors.participants}</span>}
                                
                                </div>
                                <div className="col-lg-6 mb-4">
                                    <label htmlFor="date">Date</label>
                                    <input type="date" id="date" max={today}  className={`form-control ${errors.date ? 'is-invalid' : ''}`} value={date} name="date" onChange={(e) => handleChange(e)} required />
                                    {errors.date && <span className="invalid-feedback">{errors.date}</span>}
                                
                                </div>
                                <div className="col-lg-6 mb-4">
                                    <label htmlFor="file">Upload photos (max 5 MB)</label>
                                    <input type="file" id="file" className="uploadImages form-control " onChange={handleFileChange} required />
                                    {error && <p className="text-danger">{error}</p>}
                                </div>
                                <div className="col-lg-12 text-right">
                                    <button type="submit" className="btn btn-primary px-5">Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </Box>
            </Box>
        </>
    );
};

export default Upload;
