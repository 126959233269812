import React from "react";

const DashboardIcon = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#E1B028">
                <g clip-path="url(#clip0_17_7837)">
                    <path d="M8.33333 1.5H3.44444C2.79614 1.5 2.17438 1.75754 1.71596 2.21596C1.25754 2.67438 1 3.29614 1 3.94444V8.83333C1 9.48164 1.25754 10.1034 1.71596 10.5618C2.17438 11.0202 2.79614 11.2778 3.44444 11.2778H8.33333C8.98164 11.2778 9.60339 11.0202 10.0618 10.5618C10.5202 10.1034 10.7778 9.48164 10.7778 8.83333V3.94444C10.7778 3.29614 10.5202 2.67438 10.0618 2.21596C9.60339 1.75754 8.98164 1.5 8.33333 1.5ZM3.44444 8.83333V3.94444H8.33333V8.83333H3.44444Z" fill="#E1B028" />
                    <path d="M20.5556 1.5H15.6667C15.0184 1.5 14.3966 1.75754 13.9382 2.21596C13.4798 2.67438 13.2222 3.29614 13.2222 3.94444V8.83333C13.2222 9.48164 13.4798 10.1034 13.9382 10.5618C14.3966 11.0202 15.0184 11.2778 15.6667 11.2778H20.5556C21.2039 11.2778 21.8256 11.0202 22.284 10.5618C22.7425 10.1034 23 9.48164 23 8.83333V3.94444C23 3.29614 22.7425 2.67438 22.284 2.21596C21.8256 1.75754 21.2039 1.5 20.5556 1.5ZM15.6667 8.83333V3.94444H20.5556V8.83333H15.6667Z" fill="#E1B028" />
                    <path d="M8.33333 13.7222H3.44444C2.79614 13.7222 2.17438 13.9797 1.71596 14.4381C1.25754 14.8966 1 15.5183 1 16.1666V21.0555C1 21.7038 1.25754 22.3256 1.71596 22.784C2.17438 23.2424 2.79614 23.4999 3.44444 23.4999H8.33333C8.98164 23.4999 9.60339 23.2424 10.0618 22.784C10.5202 22.3256 10.7778 21.7038 10.7778 21.0555V16.1666C10.7778 15.5183 10.5202 14.8966 10.0618 14.4381C9.60339 13.9797 8.98164 13.7222 8.33333 13.7222ZM3.44444 21.0555V16.1666H8.33333V21.0555H3.44444Z" fill="#E1B028" />
                    <path d="M20.5556 13.7222H15.6667C15.0184 13.7222 14.3966 13.9797 13.9382 14.4381C13.4798 14.8966 13.2222 15.5183 13.2222 16.1666V21.0555C13.2222 21.7038 13.4798 22.3256 13.9382 22.784C14.3966 23.2424 15.0184 23.4999 15.6667 23.4999H20.5556C21.2039 23.4999 21.8256 23.2424 22.284 22.784C22.7425 22.3256 23 21.7038 23 21.0555V16.1666C23 15.5183 22.7425 14.8966 22.284 14.4381C21.8256 13.9797 21.2039 13.7222 20.5556 13.7222ZM15.6667 21.0555V16.1666H20.5556V21.0555H15.6667Z" fill="#E1B028" />
                </g>
                <defs>
                    <clipPath id="clip0_17_7837">
                        <rect width="24" height="24" rx="5" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </>
    );
};

export default DashboardIcon;