module.exports = {
    usdtConrtactAddress:'0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
    projectId: 'f5977011bdf8f1a31a1ea68aa2d4a606',
    contractAdress:
    "0x3DC74280AC1C531042b36B5244be1c4678A5163E",//"0xE4A368AB3584c6dF4D42c8a34dd8503bc65b1402",
    contractAdressLoad:
    "0x9a2291d06d1922396ff80DC9b8274fD30100a606",//"0xE4A368AB3584c6dF4D42c8a34dd8503bc65b1402",
    CHAIN:"80001",
    adminAddress:"0xa3F2c1e70a35bA9dB96a9fb94FB91A23CcC5360c",
    testneturl:"https://testnet.bscscan.com/tx/",
    chainId:97
}