// import React from "react";
// import { Chart } from "react-google-charts";

// const BasicPie=({data})=> {


// let mainData=[]
// for(let i=0;i<data.length;i++){
//   if(i==0){
//     mainData.push(["Language", "Speakers (in millions)"])
//   }
//   mainData.push(data[i])
// }

//   const options = {
//     legend: "none",
//     pieSliceText: "label",
//     title: "",
//    // pieStartAngle: 100,
//   };

//   return (
//     <>
//    {mainData.length>0&&
//      <Chart
//       chartType="PieChart"
//       data={mainData}
//       options={options}
//       width={"100%"}
//       height={"400px"}
//     />
//   }
//   </>
//   );
// }
// export default BasicPie;


import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";

const BasicPie = ({data}) => {
  // Define state using useState hook
console.log(data)
  let series=[]
  let name=[]
  let format=[]
  for(let i=0;i<data.length;i++){
    series.push(data[i][1])
    name.push(data[i][0])
    format.push({
            title: {
              formatter: function (val) {
                return "Address";
              }
            },
            formatter: function (val) {
              return data[i][0];
            }
          })
  }

  console.log("format",format)
  const [chartData, setChartData] = useState({
    series: series,
    options:{
      chart: {
        width: 380,
        type: 'pie',
      },
      plotOptions: {
        pie: {
          startAngle: -90,
          endAngle: 270
        }
      },
      dataLabels: {
        enabled: false
      },
      fill: {
        type: 'gradient',
      },
      legend: {
        show:false,
      },
      title: {
       // text: 'Gradient Donut with custom Start-angle'
      },
      tooltip: {
        enabled: true,
        y:format,
        // y: [
        //   {
        //     title: {
        //       formatter: function (val) {
        //         return "Address";
        //       }
        //     },
        //     formatter: function (val, { seriesIndex, dataPointIndex }) {
        //       return name[dataPointIndex];
        //     }
        //   },
          
        // ],
        marker: {
          show: true
        },
        fixed: {
          enabled: true,
          position: 'topRight',
          offsetX: 0,
          offsetY: 0
        }
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
    }
  
  });

  return (
    <div>
      <div id="chart">
        {/* Render ReactApexChart component with state data */}
        <ReactApexChart  options={chartData.options} series={chartData.series} type="pie" />
      </div>
      <div id="html-dist"></div>
    </div>
  );
}

export default BasicPie;
