import React from "react";
import Header from "../directives/Header";
import Footer from "../directives/Footer";
import "../componentCss/home.css";
import "../componentCss/faq.css"
import {
    Container,
    Row,
    Col,
    Button,
    Card,
    Accordion
} from "react-bootstrap";
import { Zoom, Fade, Roll, Slide } from 'react-reveal';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaPlay } from "react-icons/fa";



const Termscondition = () => {



    return (
        <>
            <Header />
            <div className="subheader" style={{ background: "url(assets/images/termscondition.jpg)", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "top center" }} >
                <div className="d-flex justify-content-center align-items-center" style={{ height: "200px", marginTop: "74px" }} >
                    <h2 className=" text-uppercase fw-medium">
                        <span className="text-primary">Terms and conditions</span>
                    </h2>
                </div>

            </div>



            <div className="bg-galaxy py-lg-3" style={{ backgroundPosition: "bottom" }}>

                <section className="py-lg-5 py-md-5 py-sm-5  pt-3 position-relative">

                    <Container>
                        <Row className="justify-content-center">
                            <Col lg={12}>
                                <h2 className="text-primary mb-3">Terms and conditions</h2>
                                <div className="mb-4">
                                    <h6>1. General information</h6>
                                    <ul>
                                        <li className="list-group-item mt-3">1.1. The Hi5 Space project is a smart contract that does not belong to anyone. This is the specificity of the polygon cryptocurrency. A smart contract is a cryptocurrency distribution algorithm that, once uploaded to the polygon network, can no longer be edited, modified or deleted.</li>
                                        <li className="list-group-item mt-3">1.2. The Hi5 Space smart contract is uploaded to the polygon cryptocurrency network and even theoretically cannot be changed or deleted by anyone, including its creators. Therefore, no one and nothing can stop the operation of the System even if the site is closed and the administration disappears. The Hi5 Space system does not belong to anyone and works independently of the site.</li>
                                        <li className="list-group-item mt-3">1.3. The account of each participant is in a smart contract, not on the site, so it cannot be blocked or deleted even by the site administration. On the hi5space.com in the personal account (without a password), only the information of each account taken from the polygon smart contract is displayed. Therefore, it is impossible to hack the account and change the member's wallet.</li>
                                        <li className="list-group-item mt-3">1.4. The creators of this system participate on equal terms with all users, without any
                                            special privileges. They are highly experienced in developing various social community
                                            projects, not limited to crowdfunding but also encompassing charitable endeavors that
                                            aim to positively impact the global community. Therefore, <a href="https://hi5space.com/" target="_blank">Hi5 Space</a> is a project in
                                            which its creators actively engage. This engagement is likely to resonate with a wide
                                            audience, fostering an attitude of ownership and leadership to inspire positive change
                                            worldwide through principles of unity, diversity, and equality.</li>
                                    </ul>
                                </div>
                                <div className="mb-4">
                                    <h6>2. Disclaimer</h6>
                                    <ul>
                                        <li className="list-group-item mt-3">2.1. The site administration DOES NOT OWN the Hi5 Space project and does not have the technical ability to collect participants' money.</li>
                                        <li className="list-group-item mt-3">2.2. All settlements take place directly between the participants through a smart contract.</li>
                                        <li className="list-group-item mt-3">2.3. The smart contract that belongs to no one DOES NOT ACCUMULATE PARTICIPANTS' FUNDS, but instantly redirects funds between participants according to a given algorithm.</li>
                                        <li className="list-group-item mt-3">2.4. The administration of the Hi5 Space site is not responsible for the operation of the smart contract, as well as for possible errors in the code and operation of the smart contract, for possible loss of funds by system participants.</li>
                                        <li className="list-group-item mt-3">2.5. All transfers between participants are voluntary and non-refundable unilateral donations.</li>
                                        <li className="list-group-item mt-3">2.6. THE hi5space.com IS NOT A PROJECT, but displays only public information taken from the Hi5 Space smart contract, which DOES NOT BELONG to the administration of the hi5space.com.</li>
                                        <li className="list-group-item mt-3">2.7. The participant's personal account on the site is available to everyone without a
                                            password. The personal account displays the statistics of each member of the System,
                                            taken from the <a href="https://hi5space.com/" target="_blank">Hi5 Space</a> smart contract.</li>
                                    </ul>
                                </div>

                                <div className="mb-4">
                                    <h6>3. Legality and legal side</h6>
                                    <ul className="">
                                        <li className="list-group-item mt-3">3.1. The Hi5 Space project is NON-COMMERCIAL.</li>
                                        <li className="list-group-item mt-3">3.2. The words "profit", "income" and similar definitions on the site are of an advertising nature and are not a public offer.</li>
                                        <li className="list-group-item mt-3">3.3. The transfer of funds between participants is a private crowdfunding that is legal in most countries of the world and is not taxed.</li>
                                        <li className="list-group-item mt-3">3.4. The Hi5 Space project IS NOT a ponzi scheme, financial pyramid or other types of fraud, as the project does not accept, does not store participants' money, and also does not make any promises of a refund with interest.</li>
                                        <li className="list-group-item mt-3">3.5. The Hi5 Space project works on the principle of community crowdfunding affiliate
                                            system
                                            which is legal in most countries of the world.</li>
                                        <li className="list-group-item mt-3">3.6. The system CANNOT burst, as it is self-sufficient, does not give any promises of a refund and is able to work indefinitely for a long time, including without registering new participants.</li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </div>
            <div className="globe_earth">
                <Footer />
            </div>
        </>
    );
};

export default Termscondition;
