import { useState } from "react";
import Web3 from "web3";
import web3Config from "./web3Config";
import bigInt from 'big-integer';
import Erc20Abi from "./Erc20Abi.json";
import Hi5ContractAbi from "./Hi5ContractAbi.json";
import Hi5ContractLoadAbi from "./Hi5ContractLoadAbi.json";

import axios from "axios";

////New Runing Code
// const validateProvider = async (walletProvider) => {
//   console.log("validateProvider");
//   try {
//     walletProvider = await walletProvider;
//     if (!walletProvider) {
//       return {
//         status: false,
//         message: `Please connect your web3 wallet`,
//       };
//     }
//     // console.log({ walletProvider })
//     const web3 = new Web3(walletProvider);
//     let accounts = await walletProvider.request({
//       /* New */ method: "eth_requestAccounts" /* New */,
//     });

//     // const accounts = await web3.eth.getAccounts()
//     const currentProvider = await web3.currentProvider;
//     const chainId = await web3.currentProvider.chainId;
//     // let ccc = await currentProvider.getChainId()
//     // console.log({ currentProvider, chainId })

//     // if (web3.utils.toHex(parseInt(chainId)) != parseInt(web3Config.CHAIN) && parseInt(web3Config.CHAIN) != parseInt(chainId)) {
//     //     return {
//     //         status: false,
//     //         message: `Please select ${web3Config.NETWORK} network`,
//     //     }
//     // }

//     return {
//       status: true,
//       web3,
//     };
//   } catch (error) {
//     console.log("Error in validateProvider :", error);
//   }
// };


const validateProvider = async (walletProvider) => {
  console.log("validateProvider");
  try {
    walletProvider = await walletProvider;
    if (!walletProvider) {
      return {
        status: false,
        message: `Please connect your web3 wallet`,
      };
    }

    const web3 = new Web3(walletProvider);
    let accounts = await walletProvider.request({
      method: "eth_requestAccounts",
    });

    const currentProvider = await web3.currentProvider;
    const chainId = await web3.eth.getChainId();

    // Define the Matic Mainnet chain parameters
    const maticChainId = '0x89'; // 137 in decimal
    const maticNetwork = {
      chainId: maticChainId,
      chainName: "Matic Mainnet",
      nativeCurrency: {
        name: "Matic",
        symbol: "MATIC",
        decimals: 18
      },
      rpcUrls: ["https://rpc-mainnet.matic.network/"],
      blockExplorerUrls: ["https://polygonscan.com/"]
    };

    // Check if the current chain is Matic Mainnet
    if (web3.utils.toHex(chainId) !== maticChainId) {
      try {
        // Attempt to switch to Matic Mainnet
        await walletProvider.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: maticChainId }],
        });
      } catch (switchError) {
        // This error code indicates that the chain has not been added to MetaMask
        if (switchError.code === 4902) {
          try {
            // Attempt to add Matic Mainnet
            await walletProvider.request({
              method: 'wallet_addEthereumChain',
              params: [maticNetwork],
            });
          } catch (addError) {
            console.error("Failed to add Matic/POL Mainnet:", addError);
            return {
              status: false,
              message: `Failed to add Matic/POL Mainnet. Please try again.`,
            };
          }
        } else {
          console.error("Failed to switch to Matic/POL Mainnet:", switchError);
          return {
            status: false,
            message: `Failed to switch to Matic/POL Mainnet. Please try again.`,
          };
        }
      }
    }

    return {
      status: true,
      web3,
    };
  } catch (error) {
    console.log("Error in validateProvider:", error);
    return {
      status: false,
      message: `An error occurred while validating the provider.`,
    };
  }
};

const validateProviderBsc = async (walletProvider) => {
  console.log("validateProvider");
  try {
    walletProvider = await walletProvider;
    if (!walletProvider) {
      return {
        status: false,
        message: `Please connect your web3 wallet`,
      };
    }

    const web3 = new Web3(walletProvider);
    let accounts = await walletProvider.request({
      method: "eth_requestAccounts",
    });

    const currentProvider = await web3.currentProvider;
    const chainId = await web3.eth.getChainId();

    // Define the BSC Testnet chain parameters
    const bscTestnetChainId = '0x61'; // 97 in decimal
    const bscTestnetNetwork = {
      chainId: bscTestnetChainId,
      chainName: "BSC Testnet",
      nativeCurrency: {
        name: "Binance Coin",
        symbol: "BNB",
        decimals: 18
      },
      rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545/"],
      blockExplorerUrls: ["https://testnet.bscscan.com/"]
    };

    // Check if the current chain is BSC Testnet
    if (web3.utils.toHex(chainId) !== bscTestnetChainId) {
      try {
        // Attempt to switch to BSC Testnet
        await walletProvider.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: bscTestnetChainId }],
        });
      } catch (switchError) {
        // This error code indicates that the chain has not been added to MetaMask
        if (switchError.code === 4902) {
          try {
            // Attempt to add BSC Testnet
            await walletProvider.request({
              method: 'wallet_addEthereumChain',
              params: [bscTestnetNetwork],
            });
          } catch (addError) {
            console.error("Failed to add BSC Testnet:", addError);
            return {
              status: false,
              message: `Failed to add BSC Testnet. Please try again.`,
            };
          }
        } else {
          console.error("Failed to switch to BSC Testnet:", switchError);
          return {
            status: false,
            message: `Failed to switch to BSC Testnet. Please try again.`,
          };
        }
      }
    }

    return {
      status: true,
      web3,
    };
  } catch (error) {
    console.log("Error in validateProvider:", error);
    return {
      status: false,
      message: `An error occurred while validating the provider.`,
    };
  }
};

export const fetchChainId = async (walletProvider) => {
  try {
    const validate = await validateProvider(walletProvider);

    if (!validate.status) {
      return validate;
    }
    let web3 = validate.web3;
    let chainId = await web3.eth.getChainId()

    return parseInt(chainId)
  } catch (error) {
    console.log(error)
  }
}

export const getAddressValidation = async (walletAddress, walletProvider) => {
  try {
    const validate = await validateProvider(walletProvider);
    console.log(validate);

    if (!validate.status) {
      return validate;
    }

    let web3 = validate.web3;

    let isAddress = await web3.utils.isAddress(walletAddress);
    return isAddress;
  } catch (error) {
    console.error(error);
  }
};
export const purchasePlanOld = async (
  walletAddress,
  referralAddress,
  walletProvider
) => {
  console.log("purchasePlan");
  try {
    const validate = await validateProvider(walletProvider);

    if (!validate.status) {
      return validate;
    }
    let web3 = validate.web3;

    const packagePrice = 50;
    let maticBalance = await web3.eth.getBalance(walletAddress);
    maticBalance = parseInt(maticBalance)
    if (maticBalance <= 0) {
      return { status: false, message: "Insufficient MATIC/POL balance" };
    }

    let hi5Contract = new web3.eth.Contract(
      Hi5ContractAbi,
      web3Config.contractAdress
    );

    let activationAmountInMatic = await hi5Contract.methods.activationAmountInMatic().call();
    activationAmountInMatic = parseInt(activationAmountInMatic)
    if (parseFloat(maticBalance / 10 ** 18) <= parseFloat(activationAmountInMatic / 10 ** 18)) {
      return { status: false, message: `Insufficient MATIC/POL amount for activating ID. Need ${parseFloat(activationAmountInMatic / 10 ** 18)} MATIC` };
    }

    let activatePlanResult = await activatePlan(
      web3,
      hi5Contract,
      walletAddress,
      referralAddress,
      activationAmountInMatic
    );

    if (!activatePlanResult.status) {
      return { status: false, message: "Plan not purchased." };
    }

    return {
      status: true,
      message:
        "Plan purchased successsfully. Please wait it wil activate automatically",
    };
  } catch (error) {
    console.error(error);
  }
};

export const purchasePlan = async (
  walletAddress,
  referralAddress,
  walletProvider
) => {
  console.log("purchasePlan");
  try {
    const validate = await validateProvider(walletProvider);
    if (!validate.status) {
      return validate;
    }
    let web3 = validate.web3;

    const packagePrice = 50;
    let maticBalance = await web3.eth.getBalance(walletAddress);
    maticBalance = parseInt(maticBalance)
    if (maticBalance <= 0) {
      return { status: false, message: "Insufficient MATIC/POL balance" };
    }

    let hi5Contract = new web3.eth.Contract(
      Hi5ContractAbi,
      web3Config.contractAdress
    );
    let usdtContract = new web3.eth.Contract(
      Erc20Abi,
      web3Config.usdtConrtactAddress
    );

    let balanceOf = await usdtContract.methods.balanceOf(walletAddress).call();
    let decimals = await usdtContract.methods.decimals().call();
    decimals = parseInt(decimals)

    balanceOf = parseInt(balanceOf)
    balanceOf = balanceOf / 10 ** decimals;
    if (balanceOf <= packagePrice) {
      return { status: false, message: "Insufficient USDT balance" };
    }



    let allowance = await usdtContract.methods.allowance(walletAddress, web3Config.contractAdress).call();
    allowance = parseInt(allowance)
    allowance = allowance / 10 ** decimals;
    if (allowance < packagePrice) {
      let approvalResult = await callApproval(web3,
        walletAddress,
        usdtContract,
        decimals,
        packagePrice);
      if (!approvalResult.status) {
        return { status: false, message: "User reject approval" }
      }
    }

    let allowance2 = await usdtContract.methods.allowance(walletAddress, web3Config.contractAdress).call();
    allowance2 = parseInt(allowance2)
    allowance2 = allowance2 / 10 ** decimals;

    if (allowance2 < packagePrice) {
      return { status: false, message: "Insufficient approval." };
    }

    let activatePlanResult = await activatePlan(
      web3,
      hi5Contract,
      walletAddress,
      referralAddress
    );

    if (!activatePlanResult.status) {
      return { status: false, message: "Plan not purchased." };
    }

    return {
      status: true,
      message:
        "Plan purchased successsfully. Please wait it wil activate automatically",
    };
  } catch (error) {
    console.error(error);
  }
};
export const donateUsdt = async (
  walletAddress,
  amount,
  walletProvider
) => {
  console.log("donateUsdt");
  try {
    const validate = await validateProvider(walletProvider);

    if (!validate.status) {
      return validate;
    }
    let web3 = validate.web3;

    let maticBalance = await web3.eth.getBalance(walletAddress);

    let hi5Contract = new web3.eth.Contract(
      Hi5ContractAbi,
      web3Config.contractAdress
    );
    // hi5Contract has a method getMaticPrice which returns 1 matic in usdt in power of 8 
    // currently this whole method deducting the USDT but I want to deduct matic worth the usdt amount user enterd


    let usdtContract = new web3.eth.Contract(
      Erc20Abi,
      web3Config.usdtConrtactAddress
    );
    let balance = await usdtContract.methods.balanceOf(walletAddress).call();
    let decimals = await usdtContract.methods.decimals().call();

    if (maticBalance <= 0) {
      return { status: false, message: "Insufficient matic/pol balance" };
    }
    let balanceFormatted = parseInt(balance) / 10 ** parseInt(decimals)
    if (balanceFormatted <= 0) {
      return { status: false, message: "Insufficient usdt balance. Balance is 0." };
    }


    amount = amount * 10 ** parseInt(decimals)
    const transfer = await usdtContract.methods.transfer(web3Config.adminAddress, amount.toString())
    let encoded_tx = transfer.encodeABI();

    let gasPrice = await web3.eth.getGasPrice();

    let gasLimit = await web3.eth.estimateGas({
      gasPrice: web3.utils.toHex(gasPrice),
      to: web3Config.usdtConrtactAddress,
      from: walletAddress,
      data: encoded_tx,
    });

    let trx = await web3.eth.sendTransaction({
      gasPrice: web3.utils.toHex(gasPrice),
      gas: web3.utils.toHex(gasLimit),
      to: web3Config.usdtConrtactAddress,
      from: walletAddress,
      data: encoded_tx,
    });

    if (trx.transactionHash) {

      return {
        status: true,
        message:
          "Thank you for the donations."
      };
    } else {
      return {
        status: false,
        message:
          "Donation unsuccessful"
      };
    }
  } catch (error) {
    console.error(error);
    return {
      status: false,
      message:
        "Donation unsuccessful"
    };
  }
};


// export const donateUsdt = async (
//   walletAddress,
//   amount,
//   walletProvider
// ) => {
//   console.log("donateUsdt");
//   try {
//     const validate = await validateProvider(walletProvider);

//     if (!validate.status) {
//       return validate;
//     }
//     let web3 = validate.web3;

//     let maticBalance = await web3.eth.getBalance(walletAddress);

//     let hi5Contract = new web3.eth.Contract(
//       Hi5ContractAbi,
//       web3Config.contractAdress
//     );

//     // Get the price of 1 MATIC in USDT
//     let maticPriceInUsdt = await hi5Contract.methods.getMaticPrice().call();
//     maticPriceInUsdt = parseInt(maticPriceInUsdt); // Adjust if necessary depending on the return type

//     // Convert the entered USDT amount to MATIC
//     let amountInMatic = (amount * (10 ** 8)) / maticPriceInUsdt; // Adjust the power based on how the price is returned

//     // Check if the wallet has enough MATIC
//     if (web3.utils.toBN(maticBalance).lt(web3.utils.toBN(amountInMatic))) {
//       return { status: false, message: "Insufficient MATIC balance" };
//     }

//     amountInMatic = web3.utils.toWei(amountInMatic.toString(), 'ether');

//     let gasPrice = await web3.eth.getGasPrice();
//     let gasLimit = await web3.eth.estimateGas({
//       gasPrice: web3.utils.toHex(gasPrice),
//       to: web3Config.adminAddress,
//       from: walletAddress,
//       value: amountInMatic
//     });

//     let trx = await web3.eth.sendTransaction({
//       gasPrice: web3.utils.toHex(gasPrice),
//       gas: web3.utils.toHex(gasLimit),
//       to: web3Config.adminAddress,
//       from: walletAddress,
//       value: amountInMatic
//     });

//     if (trx.transactionHash) {
//       return {
//         status: true,
//         message: "Thank you for the donations."
//       };
//     } else {
//       return {
//         status: false,
//         message: "Donation unsuccessful"
//       };
//     }
//   } catch (error) {
//     console.error(error);
//     return {
//       status: false,
//       message: "Donation unsuccessful"
//     };
//   }
// };


export const donatedUsdt = async (
  walletProvider
) => {
  console.log("donatedUsdt");
  try {

    let sss = await axios.get('https://api.polygonscan.com/api?module=account&action=tokentx&address=0xa3F2c1e70a35bA9dB96a9fb94FB91A23CcC5360c&startblock=0&endblock=99999999&page=1&offset=10&sort=asc&apikey=4EDEUFZQ3SBUXJJSHY16ICAT8PZPGW832F')

    let finArr = []
    if (sss.data.result.length > 0) {
      for (let i = 0; i < sss.data.result.length; i++) {
        if (sss.data.result[i].to == "0xa3f2c1e70a35ba9db96a9fb94fb91a23ccc5360c") {
          let value = parseInt(sss.data.result[i].value) / 10 ** 6
          finArr.push(value)
        }
      }
    }

    let finalAmount = 0
    if (finArr.length > 0) {
      for (let j = 0; j < finArr.length; j++) {

        finalAmount = finalAmount + finArr[j]

      }
    }


    return {
      status: true,
      data:
        finalAmount
    };

  } catch (error) {
    console.error(error);
    return {
      status: false,
      data:
        []
    };
  }
};

export const depositInWallet = async (
  walletAddress,
  walletType,
  usdtAmount,
  spaceIdEnter,
  walletProvider
) => {
  console.log("depositInWallet");
  try {
    const validate = await validateProvider(walletProvider);

    if (!validate.status) {
      return validate;
    }
    let web3 = validate.web3;

    let maticBalance = await web3.eth.getBalance(walletAddress);
    maticBalance = parseInt(maticBalance)
    if (maticBalance <= 0) {
      return { status: false, message: "Insufficient MATIC/POL balance" };
    }

    let hi5Contract = new web3.eth.Contract(
      Hi5ContractAbi,
      web3Config.contractAdress
    );
    let usdtContract = new web3.eth.Contract(
      Erc20Abi,
      web3Config.usdtConrtactAddress
    );

    let balanceOf = await usdtContract.methods.balanceOf(walletAddress).call();
    let decimals = await usdtContract.methods.decimals().call();
    decimals = parseInt(decimals)

    balanceOf = parseInt(balanceOf)
    balanceOf = balanceOf / 10 ** decimals;
    if (balanceOf <= usdtAmount) {
      return { status: false, message: "Insufficient USDT balance" };
    }



    let allowance = await usdtContract.methods.allowance(walletAddress, web3Config.contractAdress).call();
    allowance = parseInt(allowance)
    allowance = allowance / 10 ** decimals;
    if (allowance < usdtAmount) {
      let approvalResult = await callApproval(web3,
        walletAddress,
        usdtContract,
        decimals,
        usdtAmount);
      if (!approvalResult.status) {
        return { status: false, message: "User reject approval" }
      }
    }

    let allowance2 = await usdtContract.methods.allowance(walletAddress, web3Config.contractAdress).call();
    allowance2 = parseInt(allowance2)
    allowance2 = allowance2 / 10 ** decimals;

    if (allowance2 < usdtAmount) {
      return { status: false, message: "Insufficient approval." };
    }


    let depositusdtResult = await depositusdt(
      web3,
      hi5Contract,
      walletAddress,
      walletType,
      usdtAmount,
      spaceIdEnter,
      decimals
    );

    if (!depositusdtResult.status) {
      return { status: false, message: "Deposit not completed." };
    }

    return {
      status: true,
      message:
        `Deposit successsfully to ${walletType == 0 ? "upgrade wallet" : "re-entry wallet"}.`,
    };
  } catch (error) {
    console.error(error);
    return {
      status: false,
      message:
        error,
    };
  }
};



export const depositInWalletPif = async (
  walletAddress,
  usdtAmount,
  walletProvider
) => {
  console.log("depositInWalletPif");
  try {
    const validate = await validateProvider(walletProvider);

    if (!validate.status) {
      return validate;
    }
    let web3 = validate.web3;

    let maticBalance = await web3.eth.getBalance(walletAddress);
    maticBalance = parseInt(maticBalance)
    if (maticBalance <= 0) {
      return { status: false, message: "Insufficient MATIC/POL balance" };
    }

    let hi5Contract = new web3.eth.Contract(
      Hi5ContractLoadAbi,
      web3Config.contractAdressLoad
    );
    let usdtContract = new web3.eth.Contract(
      Erc20Abi,
      web3Config.usdtConrtactAddress
    );

    let balanceOf = await usdtContract.methods.balanceOf(walletAddress).call();
    let decimals = await usdtContract.methods.decimals().call();
    decimals = parseInt(decimals)

    balanceOf = parseInt(balanceOf)
    balanceOf = balanceOf / 10 ** decimals;
    if (balanceOf <= usdtAmount) {
      return { status: false, message: "Insufficient USDT balance" };
    }



    let allowance = await usdtContract.methods.allowance(walletAddress, web3Config.contractAdressLoad).call();
    allowance = parseInt(allowance)
    allowance = allowance / 10 ** decimals;
    if (allowance < usdtAmount) {
      let approvalResult = await callApprovalLoad(web3,
        walletAddress,
        usdtContract,
        decimals,
        usdtAmount);
      if (!approvalResult.status) {
        return { status: false, message: "User reject approval" }
      }
    }

    let allowance2 = await usdtContract.methods.allowance(walletAddress, web3Config.contractAdressLoad).call();
    allowance2 = parseInt(allowance2)
    allowance2 = allowance2 / 10 ** decimals;

    if (allowance2 < usdtAmount) {
      return { status: false, message: "Insufficient approval." };
    }


    let depositusdtResult = await depositLoadusdt(
      web3,
      hi5Contract,
      walletAddress,
      usdtAmount,
      decimals
    );

    if (!depositusdtResult.status) {
      return { status: false, message: "Load not completed." };
    }

    return {
      status: true,
      message:
        `Load Completed. Please wait for blockchain confirmation.`,
    };
  } catch (error) {
    console.error(error);
    return {
      status: false,
      message:
        error,
    };
  }
};

export const getMaticAmountByUsdtAmount = async (walletProvider, value) => {
  try {

    const validate = await validateProvider(walletProvider);

    if (!validate.status) {
      return validate;
    }
    let web3 = validate.web3;

    let hi5Contract = new web3.eth.Contract(
      Hi5ContractAbi,
      web3Config.contractAdress
    );

    let getMaticPrice = await hi5Contract.methods.getMaticPrice().call()
    getMaticPrice = parseInt(getMaticPrice)
    getMaticPrice = parseFloat(getMaticPrice / 10 ** 8)
    let OneUSDT = 1 / getMaticPrice;

    let totalMatic = OneUSDT * value;

    return { status: true, value: parseFloat(totalMatic).toFixed(4) }

  } catch (error) {
    return { status: false, msg: error }
  }
}

const callApproval = async (
  web3,
  walletAddress,
  usdtContract,
  decimals,
  packagePrice
) => {
  console.log("callApproval")
  try {

    let amount = (packagePrice * 10 ** parseInt(decimals)).toLocaleString("fullwide", { useGrouping: false })
    let approve = await usdtContract.methods.approve(
      web3Config.contractAdress,
      amount
    );

    let encoded_tx = approve.encodeABI();

    let gasPrice = await web3.eth.getGasPrice();

    let gasLimit = await web3.eth.estimateGas({
      gasPrice: web3.utils.toHex(gasPrice),
      to: web3Config.usdtConrtactAddress,
      from: walletAddress,
      data: encoded_tx,
    });

    let trx = await web3.eth.sendTransaction({
      gasPrice: web3.utils.toHex(gasPrice),
      gas: web3.utils.toHex(gasLimit),
      to: web3Config.usdtConrtactAddress,
      from: walletAddress,
      data: encoded_tx,
    });

    if (trx.transactionHash) {
      return { status: true };
    }
    return { status: false, msg: "Approval faild" };
  } catch (error) {
    console.log(error);
    return { status: false, msg: error };
  }
};


const callApprovalLoad = async (
  web3,
  walletAddress,
  usdtContract,
  decimals,
  packagePrice
) => {
  console.log("callApproval")
  try {

    let amount = (packagePrice * 10 ** parseInt(decimals)).toLocaleString("fullwide", { useGrouping: false })
    let approve = await usdtContract.methods.approve(
      web3Config.contractAdressLoad,
      amount
    );

    let encoded_tx = approve.encodeABI();

    let gasPrice = await web3.eth.getGasPrice();

    let gasLimit = await web3.eth.estimateGas({
      gasPrice: web3.utils.toHex(gasPrice),
      to: web3Config.usdtConrtactAddress,
      from: walletAddress,
      data: encoded_tx,
    });

    let trx = await web3.eth.sendTransaction({
      gasPrice: web3.utils.toHex(gasPrice),
      gas: web3.utils.toHex(gasLimit),
      to: web3Config.usdtConrtactAddress,
      from: walletAddress,
      data: encoded_tx,
    });

    if (trx.transactionHash) {
      return { status: true };
    }
    return { status: false, msg: "Approval faild" };
  } catch (error) {
    console.log(error);
    return { status: false, msg: error };
  }
};

const activatePlanOld = async (
  web3,
  hi5Contract,
  walletAddress,
  referralAddress,
  activationAmountInMatic
) => {
  console.log("activatePlan")

  try {
    let activePlan = await hi5Contract.methods.activePlan(referralAddress);


    let encoded_tx = activePlan.encodeABI();


    let gasPrice = await web3.eth.getGasPrice();

    let nonce = await web3.eth.getTransactionCount(walletAddress, 'pending'); // Get the pending nonce
    let gasLimit;
    try {
      gasLimit = await web3.eth.estimateGas({
        gasPrice: web3.utils.toHex(gasPrice),
        to: web3Config.contractAdress,
        from: walletAddress,
        data: encoded_tx,
        value: activationAmountInMatic
      });
    } catch (gasError) {
      console.error("Gas estimation failed:", gasError);
      return {
        status: false,
        message: "Gas estimation failed. Please check the parameters and try again.",
      };
    }

    let trx;
    try {
      trx = await web3.eth.sendTransaction({
        nonce: web3.utils.toHex(nonce), // Include the pending nonce in the transaction
        gasPrice: web3.utils.toHex(gasPrice),
        gas: web3.utils.toHex(gasLimit),
        to: web3Config.contractAdress,
        from: walletAddress,
        data: encoded_tx,
        value: activationAmountInMatic
      });
    } catch (sendError) {
      console.error("Transaction failed:", sendError);
      return {
        status: false,
        message: "Transaction failed. Please check the parameters and try again.",
      };
    }


    if (trx.transactionHash) {
      return true;
    }
    return false;
  } catch (error) {
    console.log(error);
    return false;
  }
};

const activatePlan = async (
  web3,
  hi5Contract,
  walletAddress,
  referralAddress,
) => {
  console.log("activatePlan")

  try {
    let activePlan = await hi5Contract.methods.activatePlan(referralAddress);


    let encoded_tx = activePlan.encodeABI();


    let gasPrice = await web3.eth.getGasPrice();

    let nonce = await web3.eth.getTransactionCount(walletAddress, 'pending'); // Get the pending nonce
    let gasLimit;
    try {
      gasLimit = await web3.eth.estimateGas({
        gasPrice: web3.utils.toHex(gasPrice),
        to: web3Config.contractAdress,
        from: walletAddress,
        data: encoded_tx
      });
    } catch (gasError) {
      console.error("Gas estimation failed:", gasError);
      return {
        status: false,
        message: "Gas estimation failed. Please check the parameters and try again.",
      };
    }

    let trx;
    try {
      trx = await web3.eth.sendTransaction({
        nonce: web3.utils.toHex(nonce), // Include the pending nonce in the transaction
        gasPrice: web3.utils.toHex(gasPrice),
        gas: web3.utils.toHex(gasLimit),
        to: web3Config.contractAdress,
        from: walletAddress,
        data: encoded_tx
      });
    } catch (sendError) {
      console.error("Transaction failed:", sendError);
      return {
        status: false,
        message: "Transaction failed. Please check the parameters and try again.",
      };
    }


    if (trx.transactionHash) {
      return { status: true };
    }
    return { status: false };
  } catch (error) {
    console.log(error);
    return { status: false };
  }
};

const depositusdt = async (
  web3,
  hi5Contract,
  walletAddress,
  walletType,
  usdtAmount,
  spaceIdEnter,
  decimals
) => {
  console.log("depositusdt")

  try {
    let amount = (usdtAmount * 10 ** parseInt(decimals)).toLocaleString("fullwide", { useGrouping: false })
    let trxs;

    if (walletType == 0) {
      trxs = await hi5Contract.methods.depositToUpgradeWallet(spaceIdEnter, amount);
    } else {
      trxs = await hi5Contract.methods.depositToReEntryWallet(spaceIdEnter, amount);
    }

    let encoded_tx = trxs.encodeABI();

    let gasPrice = await web3.eth.getGasPrice();

    let nonce = await web3.eth.getTransactionCount(walletAddress, 'pending'); // Get the pending nonce

    let gasLimit;
    try {
      gasLimit = await web3.eth.estimateGas({
        gasPrice: web3.utils.toHex(gasPrice),
        to: web3Config.contractAdress,
        from: walletAddress,
        data: encoded_tx
      });
    } catch (gasError) {
      console.error("Gas estimation failed:", gasError);
      gasLimit = 200000;
    }

    let trx;
    try {

      trx = await web3.eth.sendTransaction({
        nonce: web3.utils.toHex(nonce), // Include the pending nonce in the transaction
        gasPrice: web3.utils.toHex(gasPrice),
        gas: web3.utils.toHex(gasLimit),
        to: web3Config.contractAdress,
        from: walletAddress,
        data: encoded_tx
      });
    } catch (sendError) {
      console.error("Transaction failed:", sendError);
      return {
        status: false,
        message: "Transaction failed. Please check the parameters and try again.",
      };
    }


    if (trx.transactionHash) {
      return { status: true };
    }
    return { status: false };
  } catch (error) {
    console.log(error);
    return { status: false };
  }
};

const depositLoadusdt = async (
  web3,
  hi5Contract,
  walletAddress,
  usdtAmount,
  decimals
) => {
  console.log("depositLoadusdt")

  try {
    let amount = (usdtAmount * 10 ** parseInt(decimals)).toLocaleString("fullwide", { useGrouping: false })
    let trxs;

    trxs = await hi5Contract.methods.depositToPIFWallet(amount);


    let encoded_tx = trxs.encodeABI();

    let gasPrice = await web3.eth.getGasPrice();

    let nonce = await web3.eth.getTransactionCount(walletAddress, 'pending'); // Get the pending nonce

    let gasLimit;
    try {
      gasLimit = await web3.eth.estimateGas({
        gasPrice: web3.utils.toHex(gasPrice),
        to: web3Config.contractAdressLoad,
        from: walletAddress,
        data: encoded_tx
      });
    } catch (gasError) {
      console.error("Gas estimation failed:", gasError);
      gasLimit = 200000;
    }

    let trx;
    try {

      trx = await web3.eth.sendTransaction({
        nonce: web3.utils.toHex(nonce), // Include the pending nonce in the transaction
        gasPrice: web3.utils.toHex(gasPrice),
        gas: web3.utils.toHex(gasLimit),
        to: web3Config.contractAdressLoad,
        from: walletAddress,
        data: encoded_tx
      });
    } catch (sendError) {
      console.error("Transaction failed:", sendError);
      return {
        status: false,
        message: "Transaction failed. Please check the parameters and try again.",
      };
    }


    if (trx.transactionHash) {
      return { status: true };
    }
    return { status: false };
  } catch (error) {
    console.log(error);
    return { status: false };
  }
};