import React, { useState } from "react";

import {
    Container,
    Row,
    Col,
    Card,
    Table,
    Form,
    InputGroup,
    Button
} from "react-bootstrap";
import Sidebar from "../Sidebar";
import Box from '@mui/material/Box';
import "../css/spacewall.css"
import "../css/spacewallupload.css"
import { FaPlus } from "react-icons/fa6";



const Spacewallupload = () => {


    return (
        <>
            <Box sx={{ display: '-webkit-box' }} className="dashboard">
                <Sidebar />
                <Box component="main" sx={{ flexGrow: 1, p: 3 }} style={{ marginTop: '74px', }} className="text-white mainBox">
                    <Row>

                        <Col lg={12} className="">

                            <div className="heading mb-2">
                                <h5 className="text-uppercase mb-0">Upload your wall</h5>
                            </div>
                            <Card className="overflow-hidden">

                                <Row>
                                    <Col lg={4} className="mb-4">
                                        <p className="mb-1 text-secondary">Image / Video</p>
                                        <div className="uploadImage mb-3">
                                            <div>
                                                <FaPlus fill="#E1B028" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="browseBtn">
                                                <Button variant="outline-primary" className="h-auto ml-1"> Browse</Button>
                                                <Form.Group controlId="formFile" className="">
                                                    <Form.Control type="file" />
                                                </Form.Group>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>

                    </Row>
                    
                    <Row>

                        <Col lg={12} className="mt-4">

                            <Form>
                                <Row>
                                    <Col lg={12}>
                                        <Form.Label>Name of Event</Form.Label>
                                    </Col>
                                    <Row>
                                        <Col lg={4}>
                                            <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">

                                                <Form.Control type="name" placeholder="Name" />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={4}>
                                            <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                                                <Form.Control type="date" placeholder="Date" />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Row>
                            </Form>
                        </Col>

                    </Row>
                    <Row className="mt-4">

                        <Col lg={12} className="">
                            <Row>
                                <Col lg={4} className="mb-4">
                                    <Card className="overflow-hidden">
                                        <div className="uploadImage mb-3">
                                            <div>
                                                <FaPlus fill="#E1B028" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="browseBtn">
                                                <Button variant="outline-primary" className="h-auto ml-1"> Browse</Button>
                                                <Form.Group controlId="formFile" className="">
                                                    <Form.Control type="file" />
                                                </Form.Group>
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                                <Col lg={4} className="mb-4">
                                    <Card className="overflow-hidden">
                                        <div className="uploadImage mb-3">
                                            <div>
                                                <FaPlus fill="#E1B028" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="browseBtn">
                                                <Button variant="outline-primary" className="h-auto ml-1"> Browse</Button>
                                                <Form.Group controlId="formFile" className="">
                                                    <Form.Control type="file" />
                                                </Form.Group>
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                                <Col lg={4} className="mb-4">
                                    <Card className="overflow-hidden">
                                        <div className="uploadImage mb-3">
                                            <div>
                                                <FaPlus fill="#E1B028" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="browseBtn">
                                                <Button variant="outline-primary" className="h-auto ml-1"> Browse</Button>
                                                <Form.Group controlId="formFile" className="">
                                                    <Form.Control type="file" />
                                                </Form.Group>
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>

                    </Row>
                </Box>
            </Box>


        </>
    );
};

export default Spacewallupload;
