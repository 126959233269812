import React, { useState } from "react";

import {
    Container,
    Row,
    Col,
    Card,
    Table,
    Form,
    Button
} from "react-bootstrap";
import Sidebar from "../../Sidebar";
import Box from '@mui/material/Box';





const Activities = () => {


    return (
        <>
            <Box sx={{ display: '-webkit-box' }} className="dashboard">
                <Sidebar />
                <Box component="main" sx={{ flexGrow: 1, p: 3 }} style={{ marginTop: '74px', }} className="text-white mainBox">

                    <Row>

                        <Col lg={12} className="">
                            <Row>
                                <Col lg={12} className="">
                                    <div className="heading mb-4">
                                        <h5 className="text-uppercase mb-0 text-primary">General Community Activity Ideas for Hi5 Space Social Entrepreneurs</h5>

                                    </div>
                                    <div className="mb-4">
                                        <ol className="mb-0">
                                            <li className="mb-3">
                                                Collect and Donate Things for Needy Community :
                                                <ul className="list-unstyled mt-1">
                                                    <li>▪ Clothing drives</li>
                                                    <li>▪ Food donation campaigns</li>
                                                    <li>▪ School supply drives</li>
                                                    <li>▪ Toy drives during school holidays</li>
                                                </ul>
                                            </li>
                                            <li className="mb-3">
                                                Community Health and Wellbeing :
                                                <ul className="list-unstyled mt-1">
                                                    <li>▪ Health fairs and workshops</li>
                                                    <li>▪ Fitness classes in local parks</li>
                                                    <li>▪ Meditation and stress relief sessions</li>
                                                    <li>▪ Blood donation drive</li>
                                                </ul>
                                            </li>
                                            <li className="mb-3">
                                                Animals and Pet-Related Ideas :
                                                <ul className="list-unstyled mt-1">
                                                    <li>▪ Animal shelter volunteering</li>
                                                    <li>▪ Pet adoption events</li>
                                                    <li>▪ Dog walking groups</li>
                                                    <li>▪ Fundraisers for animal welfare organizations</li>
                                                </ul>
                                            </li>
                                            <li className="mb-3">
                                                Community service ideas for senior citizens & people with Special Needs :
                                                <ul className="list-unstyled mt-1">
                                                    <li> ▪ Adaptive sports tournaments</li>
                                                    <li> ▪ Friendly community events</li>
                                                    <li> ▪ Intergenerational storytelling buddy programs</li>
                                                    <li> ▪ Virtual Tech Support classes</li>
                                                </ul>
                                            </li>
                                            <li className="mb-3">
                                                Help the Environment and Fight Climate Change :
                                                <ul className="list-unstyled mt-1">
                                                    <li> ▪ Community clean-up days</li>
                                                    <li> ▪ Tree planting initiatives</li>
                                                    <li> ▪ Recycling and waste reduction campaigns</li>
                                                    <li> ▪ Educational talks on sustainable practices</li>
                                                </ul>
                                            </li>
                                            <li className="mb-3">
                                                Any other charitable endeavors that can create a positive impact on the community
                                            </li>
                                        </ol>

                                    </div>
                                    <p><b>NOTE :</b> <span className="text-primary">When selecting a charitable endeavor, take into account the specific needs and priorities of your community.
                                        Collaborate with teams of people, working in concert with local organizations and residents to maximize the positive impact</span></p>
                                </Col>



                            </Row>


                        </Col>

                    </Row>
                </Box>
            </Box>


        </>
    );
};

export default Activities;
