


import React, { useState, useEffect } from "react";
import config from '../../../coreFiles/config';

import {
  Container,
  Nav,
  Navbar,
  NavDropdown,
  Form,
  Offcanvas,
  Modal,
  Row,
  Col,
  Button,
  Card,
  Table,
} from "react-bootstrap";
import Sidebar from "../Sidebar";
import Box from "@mui/material/Box";

import { useDispatch, useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import {
    getSciptListByUserId
} from "../../utils/apiServices";
import { useNavigate } from "react-router-dom";

const Emailscript = () => {
  const loginDetails = useSelector((state) => state.auth.LoginDetails[0]);
  let navigate = useNavigate();

 
  const [scripts, setScripts] = useState([]);



  useEffect(() => {
    fetchScriptData();
   
  }, []);


  const fetchScriptData = async () => {
    // console.log("fetchDashboardDetails");
    try {
      let result = await getSciptListByUserId(loginDetails);
      if (result.data.success) {
        setScripts(result.data.data);
      } else {
        toast.error(result.data.message);
      }
    } catch (error) {}
  };

const view=async(e,id)=>{
    e.preventDefault()
    try{
        let path = `${config.baseUrl}emailscriptView/${id}`;
       
          navigate(path);
   
    }catch(error){
        toast.error(error)
    }
   
}

  return (
    <>
    <Toaster/>
      <Box sx={{ display: "-webkit-box" }} className="dashboard">
        <Sidebar />
        <Box
          component="main"
          sx={{ flexGrow: 1, p: 3 }}
          style={{ marginTop: "74px" }}
          className="text-white mainBox"
        >
          <Row>
            <Col lg={6} className=""></Col>
        
            <Col lg={12} className="">
              <Row>
                <Col lg={12} className="mb-4">
                  <div className="heading mb-3">
                    <h5 className="text-uppercase mb-0">Scripts</h5>
                    
                  </div>
                  <Card className="cardBox overflow-hidden sameHeight">
                    <div className="d-table table-responsive">
                      <Table striped hover className="text-center mb-0">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th className="text-uppercase">NAME</th>
                        
                         
                
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {scripts.length > 0 &&
                            scripts.map((item, index) => (
                              <tr>
                                <td>{index + 1}</td>
                                <td>{item.name}</td>
                               
                                <td>
                                  
                                    <button
                                    className="btn btn-primary btn-sm"
                                      onClick={(e) => {
                                        view(e, item.id);
                                      }}
                                    >
                                      View
                                    </button>
                                 
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </div>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Box>
      </Box>


  
    </>
  );
};

export default Emailscript;
