import axios from "axios";
import config from "./config";

const client = axios.create({});

client.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        const errorResponse = JSON.parse(JSON.stringify(error));
       
        return Promise.reject(error);
    }
);

const axiosClient = (
    endpoint,
    payload = {},
    method = "get",
    headers = {}
   
) => {
    
    let axiosConfig = {
        method: method.toLowerCase(),
    };
    if (
        
        endpoint !== "login" &&
        endpoint !== "verifyOtp"
    ) {
        axiosConfig.headers = headers;
    }
    if (method.toLowerCase() === "get") {
        axiosConfig.params = payload;
    } else {
        // If payload is FormData, use it directly; otherwise, assume it's a regular object
        axiosConfig.data = payload instanceof FormData ? payload : payload;
    }


    return client(`${config.ApiURL}${endpoint}`, axiosConfig);
};
export default axiosClient;

