import React, { useState } from "react";

import {
  Container,
  Row,
  Col,
  Card,
  Table,
  Button
} from "react-bootstrap";
import Sidebar from "../Sidebar";
import Box from '@mui/material/Box';
import { useCenteredTree } from "../data/helper1";
import { makeStyles, createStyles } from "@material-ui/core/styles";
// import { getReferralUsersTreeAction, getNodeDetailsForTreeAction } from '../Action/user.action';
import Tree from 'react-d3-tree';
import '../css/custom-tree.css'

const containerStyles = {
  width: "100%",
  height: "100vh",
};

const useStyles = makeStyles(

  createStyles({
    button: {
      position: "relative",
      color: "#fff",
      width: "100px",
      height: "100px",
      textAlign: "center",
      border: "none",
      overflow: "hidden",
      background: "#30250b!important",
      borderRadius: "20px",
      "& > span": {
        flexFlow: "column"
      },
      "&:hover": {
        color: "#fff",
        background: "#30250b!important"
      }
    },
    name: {
      fontSize: "16px",
      // position:"absolute",

      // bottom: "0",
      // color:"#bc3fb1"

    },
    edit: {
      position: "absolute",
      top: "0px",
      right: "0px",
      color: "#4BA083"
    },
    attributes: {
      marginTop: "0px"
      // position: "absolute",
      // bottom: "45px",
    }
  })
);

const Referraltree = () => {
  const [toggleSet, settoggleSet] = useState(1);

  const classes = useStyles();
  const [translate, containerRef] = useCenteredTree();
  const nodeSize = { x: 300, y: 300 };
  const separation = { siblings: 1, nonSiblings: 2 };
  const foreignObjectProps = { width: nodeSize.x, height: nodeSize.y, x: -50 };

  const [isNodeOpen, setisNodeOpen] = useState(0);

  const renderForeignObjectNode = ({
    nodeDatum,
    toggleNode,
    foreignObjectProps,
    classes
  }) => (
    <>
      <foreignObject {...foreignObjectProps} className="">
        <Button
          className={classes.button}
          variant="contained"
          onClick={toggleNode}
        >

          <div className={classes.attributes}>
            <img onMouseOver={(e) => treeMouseOver(nodeDatum)} onMouseOut={(e) => treeMouseOut(nodeDatum)} src="assets/images/icon/usericon.png" alt='Profile' width={'35px'} height={'40px'} style={{ objectFit: "contain" }} />
          </div>
         
        </Button>
        <div className={classes.name} style={{width: "105px",background: "#000"}}>User <b>ID 000.1</b></div>
      </foreignObject>
    </>
  );



  const orgChart = {
    name: "User ID 000.1",
    children: [
      {
        name: "User ID 000.2",
        attributes: {
          department: "Production",
        },
        children: [
          {
            name: "User ID 000.3",
            attributes: {
              department: "Fabrication",
            },
            children: [
              {
                name: "User ID 000.4",
              },
            ],
          },
          {
            name: "User ID 000.5",
            attributes: {
              department: "Assembly",
            },
            children: [
              {
                name: "User ID 000.5",
              },
            ],
          },
        ],
      },
    ],
  };

  const treeMouseOver = async (data) => {

    // let id = data.name;
    // let id = data.data.name;
    // setnodeId(id);
    // setisNodeOpen(1);
    // let res = await getNodeDetailsForTreeAction({ 'id': id });
    // if (res.success) {
    //   setNodeDetails(res.data);
    // }
  }

  const treeMouseOut = async () => {
    setisNodeOpen(0);
  }
  const toggleManage = (data) => {
    settoggleSet(data)
  }
  return (
    <>
      <Box sx={{ display: '-webkit-box' }} className="dashboard">
        <Sidebar />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }} style={{ marginTop: '80px', }} className="text-white">
          <Row>

            <Col lg={12} className="">
              <Row>
                <Col lg={12} className=" text-center">
                  <div className="heading mb-3">
                    <h3 className="text-uppercase mb-0">Matrix referral tree</h3>
                  </div>
                </Col>
                <Col lg={12} className="mb-3">
                  <div id="treeWrapper text-center" style={{ width: "100%", height: "50em" }}>
                    {/* <Tree
                                            data={orgChart}
                                            transitionDuration="1000"
                                            pathFunc="step"
                                            rootNodeClassName="node__root"
                                            branchNodeClassName="node__branch"
                                            leafNodeClassName="node__leaf"
                                            orientation="vertical"
                                            
                                        /> */}

                    <Tree
                      data={orgChart}
                      translate={translate}
                      nodeSize={nodeSize}
                      separation={separation}
                      transitionDuration="1000"
                      pathFunc="step"
                      rootNodeClassName="node__root"
                      branchNodeClassName="node__branch"
                      leafNodeClassName="node__leaf"
                      renderCustomNodeElement={(referralUsersList) =>
                        renderForeignObjectNode({ ...referralUsersList, foreignObjectProps, classes })
                      }
                      orientation="vertical"
                    />

                  </div>
                </Col>

              </Row>


            </Col>

          </Row>
        </Box>
      </Box>


    </>
  );
};

export default Referraltree;
