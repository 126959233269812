import React from "react";

const Spacebothelp = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="23" viewBox="0 0 22 23" fill="none">
                <path d="M11 1.1875C9.57812 1.1875 8.42188 2.34375 8.42188 3.76562C8.42188 5.1875 9.57812 6.34375 11 6.34375C12.4219 6.34375 13.5781 5.1875 13.5781 3.76562C13.5781 2.34375 12.4219 1.1875 11 1.1875Z" fill="#E1B028" />
                <path d="M18.0898 12.7891C16.668 12.7891 15.5117 13.9453 15.5117 15.3672C15.5117 16.7891 16.668 17.9453 18.0898 17.9453C19.5117 17.9453 20.668 16.7891 20.668 15.3672C20.668 13.9453 19.5117 12.7891 18.0898 12.7891Z" fill="#E1B028" />
                <path d="M21.0424 17.835C20.3325 18.6829 19.2798 19.2345 18.0898 19.2345C16.8999 19.2345 15.8472 18.6829 15.1373 17.835C14.575 18.46 14.2227 19.2779 14.2227 20.183V21.1681C14.2227 21.5243 14.5109 21.8126 14.8672 21.8126H21.3555C21.7117 21.8126 22 21.5243 22 21.1681V20.183C22 19.2779 21.6047 18.46 21.0424 17.835Z" fill="#E1B028" />
                <path d="M3.91016 12.7891C2.48828 12.7891 1.33203 13.9453 1.33203 15.3672C1.33203 16.7891 2.48828 17.9453 3.91016 17.9453C5.33204 17.9453 6.48828 16.7891 6.48828 15.3672C6.48828 13.9453 5.33204 12.7891 3.91016 12.7891Z" fill="#E1B028" />
                <path d="M6.86271 17.835C6.15278 18.6829 5.10009 19.2345 3.91016 19.2345C2.72022 19.2345 1.66753 18.6829 0.957602 17.835C0.39527 18.46 0 19.2779 0 20.183V21.1681C0 21.5243 0.288277 21.8126 0.644531 21.8126H7.13281C7.48907 21.8126 7.77734 21.5243 7.77734 21.1681V20.183C7.77734 19.2779 7.42504 18.46 6.86271 17.835Z" fill="#E1B028" />
                <path d="M14.2227 10.2111C14.5789 10.2111 14.8672 9.92278 14.8672 9.56653V8.58147C14.8672 7.67637 14.5149 6.85842 13.9526 6.2334C13.2426 7.08139 12.1899 7.63293 11 7.63293C9.81007 7.63293 8.75737 7.08139 8.04745 6.2334C7.48511 6.85842 7.13281 7.67637 7.13281 8.58147V9.56653C7.13281 9.92278 7.42109 10.2111 7.77734 10.2111H10.3555V14.3204L7.75461 15.5923C7.71882 16.2021 7.54243 16.7696 7.2554 17.2716L10.9969 15.4419L14.7777 17.3325C14.4758 16.8258 14.2909 16.2477 14.2489 15.6267L11.6445 14.3244V10.2111H14.2227Z" fill="#E1B028" />
            </svg>
        </>
    );
};

export default Spacebothelp;