import React, { useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import '../../component/Dashboard/css/donutchart.css'

const Piechart = ({levels}) => {
  const series = [levels.level1, levels.level2, levels.level3];
  const options = {
    chart: {
      width: 200,
      type: 'donut',
      stroke:'#000'
      
    },
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 270,
        cx: 150,
        cy: 95,
      }
    },
    dataLabels: {
      enabled: false
    },
    fill: {
      type: 'gradient',
    },
    
    legend: {
        position: `bottom`,
        horizontalAlign: 'center',
        floating: false,
        fontSize: '14px',
        markers: {
          width: 12,
          height: 12,
          radius: 2,
          strokeWidth: 0,
          offsetX: 0,
          offsetY: 0,
          shape: 'square'
        },
      formatter: function(level, opts) {
        return "level" + ` - ${opts.seriesIndex +1} ` + opts.w.globals.series[opts.seriesIndex]
      },
      labels: {
        colors: ['#fff', '#fff', '#fff', '#fff', '#fff'],
        lineHeight:"2"
      }
    },
    border: {
        show: true,
        color: '#ccc',
        width: 1,
        radius: 2,
        dashArray: 0
      },
    responsive: [{
      breakpoint: 1399,
      breakpoint: 480,
      options: {
        chart: {
          width: 380
        },
        legend: {
          position: 'bottom'
        }
      }
    }]
  };

  useEffect(() => {
  }, []);

  return (
    <div>
         <div className="donut-chart-container">
      <div id="chart">
        <ReactApexChart options={options} series={series} type="donut" width={380} />
      </div>
      <div id="html-dist"></div>
      </div>
    </div>
  );
};

export default Piechart;