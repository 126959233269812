import React, { useState } from "react";

import {
    Container,
    Row,
    Col,
    Card,
    Table
} from "react-bootstrap";
import Sidebar from "../Sidebar";
import Box from '@mui/material/Box';


const Directdonation = () => {


    return (
        <>
            <Box sx={{ display: '-webkit-box' }} className="dashboard">
                <Sidebar/>
                <Box component="main" sx={{ flexGrow: 1, p: 3 }} style={{ marginTop: '74px', }} className="text-white mainBox">
                    <Row>
                        <Col lg={6} className="">
                        </Col>
                        <Col lg={6} className="mb-4">
                            <div className="Tablist w-100">
                                <div className="d-sm-flex t-list mb-0 justify-content-between w-100 align-items-center">
                                    <div className="">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="pr-2 border-right">
                                                <h6 className="mb-0">Status</h6>
                                            </div>
                                            <div className="pl-2">
                                                <h5 className="text-primary mb-0">Direct Donation</h5>
                                            </div>
                                        </div>

                                    </div>

                                    <div className=" border-0">
                                        <div className="">
                                            <h6 className="text-blue mb-0">50 Day Left</h6>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </Col>
                        <Col lg={12} className="">
                            <Row>
                                <Col lg={12} className="mb-4">
                                    <div className="heading mb-3">
                                        <h5 className="text-uppercase mb-0">Direct Donation</h5>
                                    </div>
                                    <Card className="cardBox overflow-hidden sameHeight">
                                        <div className="d-table table-responsive">
                                        <Table striped hover className="text-center mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th className="text-uppercase">NAME</th>
                                                        <th className="text-uppercase">Donation amount</th>
                                                        <th className="text-uppercase">Wallet Address</th>
                                                        <th className="text-uppercase">Date</th>
                                                        <th className="text-uppercase">Amount</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>1</td>
                                                        <td>Hi5space</td>
                                                        <td>$125010</td>
                                                        <td>hi5space1235mdhd555</td>
                                                        <td>01/02/2024</td>
                                                        <td>$50125</td>
                                                    </tr>
                                                    <tr>
                                                        <td>2</td>
                                                        <td>Hi5space</td>
                                                        <td>$125010</td>
                                                        <td>hi5space1235mdhd555</td>
                                                        <td>01/02/2024</td>
                                                        <td>$50125</td>
                                                    </tr>
                                                    <tr>
                                                        <td>3</td>
                                                        <td>Hi5space</td>
                                                        <td>$125010</td>
                                                        <td>hi5space1235mdhd555</td>
                                                        <td>01/02/2024</td>
                                                        <td>$50125</td>
                                                    </tr>
                                                    <tr>
                                                        <td>4</td>
                                                        <td>Hi5space</td>
                                                        <td>$125010</td>
                                                        <td>hi5space1235mdhd555</td>
                                                        <td>01/02/2024</td>
                                                        <td>$50125</td>
                                                    </tr>
                                                    <tr>
                                                        <td>5</td>
                                                        <td>Hi5space</td>
                                                        <td>$125010</td>
                                                        <td>hi5space1235mdhd555</td>
                                                        <td>01/02/2024</td>
                                                        <td>$50125</td>
                                                    </tr>
                                                    <tr>
                                                        <td>6</td>
                                                        <td>Hi5space</td>
                                                        <td>$125010</td>
                                                        <td>hi5space1235mdhd555</td>
                                                        <td>01/02/2024</td>
                                                        <td>$50125</td>
                                                    </tr>
                                                    <tr>
                                                        <td>7</td>
                                                        <td>Hi5space</td>
                                                        <td>$125010</td>
                                                        <td>hi5space1235mdhd555</td>
                                                        <td>01/02/2024</td>
                                                        <td>$50125</td>
                                                    </tr>
                                                    <tr>
                                                        <td>8</td>
                                                        <td>Hi5space</td>
                                                        <td>$125010</td>
                                                        <td>hi5space1235mdhd555</td>
                                                        <td>01/02/2024</td>
                                                        <td>$50125</td>
                                                    </tr>
                                                </tbody>
                                            </Table>

                                        </div>
                                    </Card>
                                </Col>
                               
                            </Row>


                        </Col>
                        
                    </Row>
                </Box>
            </Box>


        </>
    );
};

export default Directdonation;
