import React, { useState } from "react";

import {
    Container,
    Row,
    Col,
    Card,
    Table,
    Form,
    Button
} from "react-bootstrap";
import Sidebar from "../Sidebar";
import Box from '@mui/material/Box';


const SpaceInvite = () => {

    return (
        <>
            <Box sx={{ display: '-webkit-box' }} className="dashboard">
                <Sidebar />
                <Box component="main" sx={{ flexGrow: 1, p: 3 }} style={{ marginTop: '74px', }} className="text-white mainBox">

                    <Row>

                        <Col lg={12} className="">
                            <Row>
                                <Col lg={12} className="">
                       
                                    <div className="mb-4">
                                        <p className="mb-4"> Welcome to Hi5 Space, a vibrant global platform dedicated to empowering social entrepreneurs and change-makers like you!
                                            At Hi5 Space, we've crafted an ingenious system designed by social entrepreneurs, for social entrepreneurs. Our independent socio-economic movement is all about
                                            inspiring, educating, and elevating each member of our vibrant community.</p>
                                        <p>Within the Hi5 Space community, collaboration and cooperation are our guiding principles. Our reciprocal donation sharing movement fosters a sense of shared
                                            responsibility, recognizing that everyone has something valuable to contribute. Together, we uplift, we inspire, and we create ripples of transformation that touch lives far
                                            and wide. Let's embark on this remarkable journey together, united in purpose and driven by the desire to create a better tomorrow for everyone.</p>

                                    </div>
                                </Col>
                                <Col lg={12} className="mb-4">
                                    <div className="heading mb-4 ">
                                        <h5 className="text-uppercase mb-0 text-primary">A MESSAGE FROM HI5 SPACE FOR YOUR LIFE JOURNEY:</h5>
                                    </div>

                                    <div className="heading mb-4">
                                        <h6 className="text-uppercase mb-4">LIFE IS NOT WHAT YOU SEE….. BUT WHAT YOU WANT TO SEE….</h6>
                                       
                                        <p className="mb-4">Life is a remarkable gift that offers us the privilege, opportunity, and responsibility to give something back to our community.
                                            Giving is a vital part of our spiritual formation and an active expression of our faith. </p>

                                        <p className="mb-4">The realization of our journey in life is to give and make others happy, and the pleasure that comes from giving is immeasurable.
                                            Nothing in this world is permanent. We all come into this world empty-handed and will leave empty-handed one day.</p>

                                            <p className="mb-4">Real happiness is found in giving, sharing, and helping one another through both joyful and challenging times.
                                            Remember that what you have today was someone else's in the past, and it will be someone else's in the future.</p>

                                        <p className="mb-4">The ultimate truth lies in understanding that what we do for others today becomes the most precious element of our life's journey.
                                        The act of giving requires the highest standard of personal integrity and discipline.</p>

                                        <p className="mb-4">Join the Hi5 Space Community and actively contribute to making a positive difference and creating a brighter future for ourselves and the world.</p>
                                        
                                        <h6 className="text-uppercase text-primary">Let's give willingly, generously, and cheerfully by donating USD50 now and becoming an esteemed Hi5 Space Active Donor.</h6>
                                    </div>

                                </Col>


                            </Row>


                        </Col>

                    </Row>
                </Box>
            </Box>


        </>
    );
};

export default SpaceInvite;
