import React, { useState, useEffect, useInsertionEffect } from "react";
import Header from "../directives/Header";
import Footer from "../directives/Footer";
import "../componentCss/home.css";
import "../componentCss/about.css"
import "../componentCss/blog.css"
import {
    Container,
    Row,
    Col,
    Button,
    Card
} from "react-bootstrap";
import { Zoom, Fade, Roll, Slide } from 'react-reveal';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaPlay } from "react-icons/fa";
import { eventsGetApi } from "../utils/apiServices"
import config from "../../coreFiles/config"

const Spacewall = () => {

    const [spaceWallData, setSpaceWallData] = useState([])
    useEffect(() => {
        fetchSpaceWalle()
    }, []);

    const fetchSpaceWalle = async () => {
        try {

            let result = await eventsGetApi();
            setSpaceWallData(result.data.data)
        } catch (error) {
            console.log(error)
        }
    }

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    centerPadding: "60px",
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <>
            <Header />
            <div className="subheader" >
                <Container>
                    <Row className="justify-content-center mt-5">
                        <Col lg={10} md={10} xs={11} className="mt-5">

                            <div className="bannerBlog mt-5 pt-5 text-center">
                                <h3 className="mb-1">SUPPORT NEEDY PEOPLE THROUGH THE GLOBAL</h3>
                                <h3> Hi5 SPACE SOCIAL RESPONSIBILITY MOVEMENT</h3>
                                <div className="my-4">
                                    <h3 className="tracking-wide text-primary">GIVE SOMETHING TO THOSE WHO HAVE NOTHING</h3>
                                </div>
                                <img src="assets/images/blog/blog.png" className="bannerContent" />
                                {/* <div className="cardBlock p-4 bg-black rounded-3">
                                    <Button className="mb-2">Technology</Button>
                                    <h3>The Impact of Technology on the Workplace: How Technology is Changing</h3>
                                    <div className="d-flex align-items-center mt-3">
                                        <div className="me-3 small">
                                            <span className="me-2"><img src="assets/images/blog/pro.png" /></span>Tracey Wilson
                                        </div>
                                        <div className="small">
                                            August 20, 2022
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>



            <div className="bg-galaxy py-lg-5" style={{ backgroundPosition: "bottom" }}>
                <section className="pt-3 position-relative">
                    <Container className="">
                        <Row>
                            <Col lg={12}>
                                <div className="heading mb-4">
                                    <h2>Hi5 SPACE COMMUNITY REWARDS</h2>
                                </div>
                                <div>
                                    <p>
                                        Each month, commencing from the 5th day, the Space Reward Fund Sharing initiative comes into effect.
                                        The space community rewards provides automatic monthly reward sharing to committed active donors
                                        who actively participate in community activities. These rewards are designed to incentivize individuals or
                                        groups to support the Hi5 Space community by acknowledging and appreciating their contributions of
                                        time, effort, or resources.  </p>
                                    <p>Through this approach, you can cultivate an enjoyable journey for your community participants, while also
                                        nurturing social entrepreneurship and fostering a culture of active engagement to assist those in need.</p>
                                    <p className="mb-0">In order to qualify for space reward funds, individuals need to showcase an image of their charitable
                                        activity on the space wall. This should include the Hi5 Space logo worn on clothing items like shirts, hats,
                                        flags, or any other identification associated with the Hi5 Space community.
                                    </p>
                                </div>
                            </Col>
                        </Row>

                    </Container>

                </section>

                <section className="py-lg-5 py-md-5 py-sm-5  pt-3 position-relative">

                    <Container className=" my-3">
                        <Row>


                            {spaceWallData.length > 0 &&
                                spaceWallData.map((item) => (
                                    <Col lg={4} className="mb-4">
                                        <Card className="bg-light-grey p-4 rounded-5 border border-dark">
                                            <div className="mb-3">
                                                {/* <img src="assets/images/social/2.png" /> */}
                                                <img src={`${config.ApiURL}eventsImage/${item.imageName}`} height={`223px`} width={` 100%;`} className="object-fit-contain" />
                                            </div>
                                            <div>
                                                <span className="bg-toast text-primary fs-6">{item.eventName}</span>
                                                <h5 className="mt-3 mb-3">{item.description}</h5>
                                                <div className="d-flex align-items-center">
                                                    <div className="me-3 small d-flex align-items-center">
                                                        <span className="me-2"><img src={`https://flagsapi.com/${item.sortname}/flat/64.png`} width={`24px`} height={`24px`} className="object-fit-contain" /></span>{item.country}
                                                    </div>
                                                    <div className="small">
                                                        {item.date}
                                                    </div>

                                                </div>
                                            </div>

                                        </Card>
                                    </Col>
                                ))}

                            {/* <Col lg={4} className="mb-4">
                                <Card className="bg-light-grey p-4 rounded-5 border border-dark">
                                    <div className="mb-3">
                                        <img src="assets/images/social/2.png" />
                                    </div>
                                    <div>
                                        <span className="bg-toast text-primary fs-6">Technology</span>
                                        <h5 className="mt-3 mb-3">The Impact of Technology on the Workplace: How Technology is Changing</h5>
                                        <div className="d-flex align-items-center">
                                            <div className="me-3 small">
                                                <span className="me-2"><img src="assets/images/blog/pro.png" /></span>Tracey Wilson
                                            </div>
                                            <div className="small">
                                                August 20, 2022
                                            </div>

                                        </div>
                                    </div>

                                </Card>
                            </Col>
                            <Col lg={4} className="mb-4">
                                <Card className="bg-light-grey p-4 rounded-5 border border-dark">
                                    <div className="mb-3">
                                        <img src="assets/images/social/3.png" />
                                    </div>
                                    <div>
                                        <span className="bg-toast text-primary fs-6">Technology</span>
                                        <h5 className="mt-3 mb-3">The Impact of Technology on the Workplace: How Technology is Changing</h5>
                                        <div className="d-flex align-items-center">
                                            <div className="me-3 small">
                                                <span className="me-2"><img src="assets/images/blog/pro.png" /></span>Tracey Wilson
                                            </div>
                                            <div className="small">
                                                August 20, 2022
                                            </div>

                                        </div>
                                    </div>

                                </Card>
                            </Col>
                            <Col lg={4} className="mb-4">
                                <Card className="bg-light-grey p-4 rounded-5 border border-dark">
                                    <div className="mb-3">
                                        <img src="assets/images/blog/4.png" />
                                    </div>
                                    <div>
                                        <span className="bg-toast text-primary fs-6">Technology</span>
                                        <h5 className="mt-3 mb-3">The Impact of Technology on the Workplace: How Technology is Changing</h5>
                                        <div className="d-flex align-items-center">
                                            <div className="me-3 small">
                                                <span className="me-2"><img src="assets/images/blog/pro.png" /></span>Tracey Wilson
                                            </div>
                                            <div className="small">
                                                August 20, 2022
                                            </div>

                                        </div>
                                    </div>

                                </Card>
                            </Col>
                            <Col lg={4} className="mb-4">
                                <Card className="bg-light-grey p-4 rounded-5 border border-dark">
                                    <div className="mb-3">
                                        <img src="assets/images/blog/5.png" />
                                    </div>
                                    <div>
                                        <span className="bg-toast text-primary fs-6">Technology</span>
                                        <h5 className="mt-3 mb-3">The Impact of Technology on the Workplace: How Technology is Changing</h5>
                                        <div className="d-flex align-items-center">
                                            <div className="me-3 small">
                                                <span className="me-2"><img src="assets/images/blog/pro.png" /></span>Tracey Wilson
                                            </div>
                                            <div className="small">
                                                August 20, 2022
                                            </div>

                                        </div>
                                    </div>

                                </Card>
                            </Col>
                            <Col lg={4} className="mb-4">
                                <Card className="bg-light-grey p-4 rounded-5 border border-dark">
                                    <div className="mb-3">
                                        <img src="assets/images/blog/6.png" />
                                    </div>
                                    <div>
                                        <span className="bg-toast text-primary fs-6">Technology</span>
                                        <h5 className="mt-3 mb-3">The Impact of Technology on the Workplace: How Technology is Changing</h5>
                                        <div className="d-flex align-items-center">
                                            <div className="me-3 small">
                                                <span className="me-2"><img src="assets/images/blog/pro.png" /></span>Tracey Wilson
                                            </div>
                                            <div className="small">
                                                August 20, 2022
                                            </div>

                                        </div>
                                    </div>

                                </Card>
                            </Col>
                            <Col lg={4} className="mb-4">
                                <Card className="bg-light-grey p-4 rounded-5 border border-dark">
                                    <div className="mb-3">
                                        <img src="assets/images/blog/7.png" />
                                    </div>
                                    <div>
                                        <span className="bg-toast text-primary fs-6">Technology</span>
                                        <h5 className="mt-3 mb-3">The Impact of Technology on the Workplace: How Technology is Changing</h5>
                                        <div className="d-flex align-items-center">
                                            <div className="me-3 small">
                                                <span className="me-2"><img src="assets/images/blog/pro.png" /></span>Tracey Wilson
                                            </div>
                                            <div className="small">
                                                August 20, 2022
                                            </div>

                                        </div>
                                    </div>

                                </Card>
                            </Col>
                            <Col lg={4} className="mb-4">
                                <Card className="bg-light-grey p-4 rounded-5 border border-dark">
                                    <div className="mb-3">
                                        <img src="assets/images/blog/8.png" />
                                    </div>
                                    <div>
                                        <span className="bg-toast text-primary fs-6">Technology</span>
                                        <h5 className="mt-3 mb-3">The Impact of Technology on the Workplace: How Technology is Changing</h5>
                                        <div className="d-flex align-items-center">
                                            <div className="me-3 small">
                                                <span className="me-2"><img src="assets/images/blog/pro.png" /></span>Tracey Wilson
                                            </div>
                                            <div className="small">
                                                August 20, 2022
                                            </div>

                                        </div>
                                    </div>

                                </Card>
                            </Col>
                            <Col lg={4} className="mb-4">
                                <Card className="bg-light-grey p-4 rounded-5 border border-dark">
                                    <div className="mb-3">
                                        <img src="assets/images/blog/9.png" />
                                    </div>
                                    <div>
                                        <span className="bg-toast text-primary fs-6">Technology</span>
                                        <h5 className="mt-3 mb-3">The Impact of Technology on the Workplace: How Technology is Changing</h5>
                                        <div className="d-flex align-items-center">
                                            <div className="me-3 small">
                                                <span className="me-2"><img src="assets/images/blog/pro.png" /></span>Tracey Wilson
                                            </div>
                                            <div className="small">
                                                August 20, 2022
                                            </div>

                                        </div>
                                    </div>

                                </Card>
                            </Col> */}


                        </Row>
                    </Container>
                </section>


            </div>
            <div className="globe_earth">
                <Footer />
            </div>
        </>
    );
};

export default Spacewall;
